import { Container, Row, Col } from 'react-bootstrap';
import ProfileSection from '../../../components/organisms/description-sections/profile-update-section/ProfileSection';
import { ButtonData, SUB_TITLE, TITLE } from './constants';
import Couple from '../../../assests/images/couple.png';
import CheckIcon from '../../../assests/icons/CheckIcon';
import { useState } from 'react';
import CheckButton from '../../../components/molecules/Buttons/CheckButton/CheckButton';

interface PreferenceProps {
  onDataChange?: (preference: string) => void;
}

const initialButtonData = [
  {
    primaryText: 'More pleasure to partner',
    checkedIcon: <CheckIcon />,
    isSelected: false,
  },
  {
    primaryText: 'More pleasure to me',
    checkedIcon: <CheckIcon />,
    isSelected: false,
  },
];
const Preference = ({ onDataChange }: PreferenceProps) => {
  const [buttonData, setButtonData] = useState<ButtonData[]>(initialButtonData);

  const handleButtonClick = (index: number) => {
    setButtonData(prevData =>
      prevData.map((button, i) => {
        if (i === index) {
          return { ...button, isSelected: !button.isSelected };
        }
        return { ...button, isSelected: false };
      }),
    );

    if (onDataChange) {
      onDataChange(buttonData[index].primaryText);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center left-tab w-100 flex-column"
        >
          <ProfileSection title={TITLE} description={SUB_TITLE} />
          <img
            src={Couple}
            alt="couple img"
            width="300px"
            height="300px"
            style={{ marginTop: '30px' }}
          />

          <div
            style={{ marginTop: '50px' }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {buttonData.map((button, index) => (
              <CheckButton
                key={index}
                primaryText={button.primaryText}
                checkedIcon={button.isSelected ? button.checkedIcon : null}
                isSelected={button.isSelected}
                onClick={() => handleButtonClick(index)}
              />
            ))}
          </div>

          {/* <DateSection onFormChange={handleDataChange} /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Preference;
