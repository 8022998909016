import {
  ALIGNMENT,
  TEXT_TITLE,
  TEXT_DESC,
  MARGIN_TOP_TITLE,
  MARGIN_TOP_DESC,
  FONT_WEIGHT_TITLE,
  FONT_WEIGHT_DESC,
  FONT_SIZE_DESC,
  FONT_SIZE_TITLE,
  LINE_HEIGHT_TITLE,
  LINE_HEIGHT_DESC,
  WIDTH_TITLE,
  CONSIDER_LINE_HEIGHT_MOBILE,
  SMALL_MOBILE_WIDTH_DESC,
} from '.';
import TextBlock from '../../../molecules/TextBlock/MainHeadingDescription/TextBlock';
interface DashboardDescriptionProps {}

const DashboardDescription = () => {
  const isLargerMobileScreen = window.innerWidth >= 401;
  const appliedWidth = isLargerMobileScreen && isLargerMobileScreen ? 'unset' : WIDTH_TITLE;
  return (
    <TextBlock
      alignment={ALIGNMENT}
      textTitle={TEXT_TITLE}
      textDesc={TEXT_DESC}
      marginTopTitle={MARGIN_TOP_TITLE}
      marginTopDesc={MARGIN_TOP_DESC}
      fontWeightTitle={FONT_WEIGHT_TITLE}
      fontWeightDesc={FONT_WEIGHT_DESC}
      fontSizeDesc={FONT_SIZE_DESC}
      fontSizeTitle={FONT_SIZE_TITLE}
      lineHeightTitle={LINE_HEIGHT_TITLE}
      lineHeightDesc={LINE_HEIGHT_DESC}
      widthTitle={appliedWidth}
      considerLineHeight={CONSIDER_LINE_HEIGHT_MOBILE}
      smallMobileDescWidth={SMALL_MOBILE_WIDTH_DESC}
    />
  );
};

export default DashboardDescription;
