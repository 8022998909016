import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../app/api/axiosInstance';
import { AUTH_URLS, STATE_KEY } from '../constants';
import { LoginResponse } from '../types';

 const googleLogin = createAsyncThunk<LoginResponse, string, { rejectValue: string }>(
  `${STATE_KEY}/google`,
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<LoginResponse>(AUTH_URLS.GOOGLE_LOGIN, { code });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export { googleLogin }
