const UserIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90743 11.0152H9.9341C12.3741 11.0152 14.3583 9.03102 14.3583 6.59102C14.3583 4.15102 12.3741 2.16602 9.9341 2.16602C7.49327 2.16602 5.50827 4.15102 5.50827 6.58852C5.49993 9.02185 7.47243 11.0077 9.90743 11.0152ZM6.69827 6.59102C6.69827 4.80685 8.14993 3.35602 9.9341 3.35602C11.7174 3.35602 13.1683 4.80685 13.1683 6.59102C13.1683 8.37435 11.7174 9.82602 9.9341 9.82602H9.90993C8.13327 9.81935 6.69243 8.36935 6.69827 6.59102Z"
        fill="#212121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 15.6438C3.33325 18.7246 8.30159 18.7246 9.93409 18.7246C12.7666 18.7246 16.5333 18.4071 16.5333 15.6605C16.5333 12.5796 11.5666 12.5796 9.93409 12.5796C7.10075 12.5796 3.33325 12.8971 3.33325 15.6438ZM4.58325 15.6438C4.58325 14.4396 6.38325 13.8296 9.93409 13.8296C13.4841 13.8296 15.2833 14.4455 15.2833 15.6605C15.2833 16.8646 13.4841 17.4746 9.93409 17.4746C6.38325 17.4746 4.58325 16.8588 4.58325 15.6438Z"
        fill="#212121"
      />
    </svg>
  );
};

export default UserIcon;
