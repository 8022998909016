import { Container, Row, Col } from 'react-bootstrap';
import BackButton from '../../../components/molecules/Buttons/BackButton/BackButton';
import Action from '../../../components/organisms/action-section/Action';
import { ButtonText } from '../../../components/types/ButtonTypes';
import { useEffect, useState } from 'react';
import OtpContainer from '../../../components/organisms/otp-section/OtpContainer';
import AccountVerifyDescription from '../../../components/organisms/description-sections/account-verify-description/AccountVerifyDescription';
import { ACTION_CLASS } from '../../../components/organisms/action-section';
import useScrollToTop from '../../../app/hooks/useScrollToTop';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectRoutes } from '../../../slices/navigation/navigationSlice';
import { ROUTES } from '../../../config/routes';
import { useAppDispatch } from '../../../app/hooks/useAppDispatch';
import { verify } from '../../../slices/auth/thunk/verify';
import { hideLoader, showLoader } from '../../../slices/loader';
import { LoaderTitleTypes } from '../../../components/types/loaderTitleTypes';
import useToast, { ToastType } from '../../../app/hooks/useToast';
import { getSignUpErrorMessage } from '../../../app/utils/errorMessages';

const AccountVerify = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const routes = useSelector(selectRoutes);
  const [previousRoute] = routes;

  const [formValues, setFormValues] = useState({ otp: '' });

  const email = new URLSearchParams(location.search).get('email');

  const handleAccountVerify = () => {
    switch (previousRoute) {
      case ROUTES.FORGOT_PASSWORD:
        return navigate(`${ROUTES.RESET_PASSWORD}?otp=${formValues.otp}&email=${email}`);
      case ROUTES.SIGNUP:
        return handleAccountRegisterVerify();
      default:
        return undefined;
    }
  };

  const handleOtpComplete = (otp: string) => {
    setFormValues({ otp: otp });
  };

  const getButtonText = () => {
    switch (previousRoute) {
      case ROUTES.FORGOT_PASSWORD:
        return ButtonText.ConfirmCode;
      case ROUTES.SIGNUP:
        return ButtonText.AccountVerify;
      default:
        return ButtonText.Continue;
    }
  };

  const handleAccountRegisterVerify = async () => {
    dispatch(showLoader(LoaderTitleTypes.DEFAULT));

    try {
      const result = await dispatch(
        verify({ email: email!, verificationCode: formValues.otp }),
      ).unwrap();
      console.log(result);

      showToast(ToastType.SUCCESS, 'Account verification successful');
      navigate(ROUTES.LOGIN);
    } catch (error: any) {
      if (error) {
        showToast(ToastType.ERROR, error.error);
      } else {
        console.error('An unknown error occurred:', error);
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div>
      <BackButton />
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Row className="text-center w-100">
          <Col
            xs={12}
            md={6}
            className="d-flex align-items-center justify-content-center left-tab w-100"
          >
            <div>
              <AccountVerifyDescription />
              <OtpContainer onComplete={handleOtpComplete} />
              <Action
                buttonName={getButtonText()}
                onClick={handleAccountVerify}
                className={ACTION_CLASS.WEB}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-center justify-content-center w-100"
          >
            <Action
              buttonName={getButtonText()}
              onClick={handleAccountVerify}
              className={ACTION_CLASS.MOBILE_BOTTOM}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccountVerify;
