import { Alignment } from "../../../../types/textTypes";

export const TITLE = 'Already have an account?';
export const ALIGNMENT = Alignment.CENTER;
export const FONT_SIZE = '18px';
export const FONT_WEIGHT = '400';
export const LINE_HEIGHT = '28.8px';
export const LETTER_SPACING = '0.2px'

export const URL_TITLE = 'Log in';
export const FONT_WEIGHT_URL = '600';
export const LOGIN_URL = "/login"

export const SMALL_MOBILE_FONT_SIZE = '12px';
export const SMALL_MOBILE_LINE_HEIGHT = '22px';