import { createAsyncThunk } from "@reduxjs/toolkit";
import { VerifyRequest, VerifyResponse } from "../types";
import axiosInstance from "../../../app/api/axiosInstance";
import { AUTH_URLS, STATE_KEY } from "../constants";

export const verify = createAsyncThunk<VerifyResponse, VerifyRequest>(
    `${STATE_KEY}/verifyUser`,
    async (requestData, { rejectWithValue }) => {
        try {
            const response =  await axiosInstance.post<VerifyResponse>(AUTH_URLS.VERIFY_USER, requestData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Verification failed');
        }
    }
);