interface CLoseEyeIconProps {}

const CloseEyeIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.13411 13.5142C7.97411 13.5142 7.81411 13.4534 7.69245 13.3309C7.07745 12.7167 6.73828 11.9001 6.73828 11.0317C6.73828 9.23174 8.20161 7.76758 9.99995 7.76758C10.8649 7.76758 11.7049 8.11674 12.3041 8.72591C12.5458 8.97258 12.5433 9.36758 12.2966 9.60924C12.0508 9.85258 11.6558 9.84841 11.4133 9.60341C11.0474 9.23091 10.5324 9.01758 9.99995 9.01758C8.89078 9.01758 7.98828 9.92091 7.98828 11.0317C7.98828 11.5659 8.19745 12.0692 8.57578 12.4476C8.81995 12.6917 8.81995 13.0867 8.57661 13.3309C8.45411 13.4534 8.29411 13.5142 8.13411 13.5142Z"
        fill="#212121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.473 14.2426C10.1771 14.2426 9.91381 14.0309 9.85881 13.7293C9.79714 13.3901 10.0221 13.0643 10.3621 13.0026C11.1788 12.8543 11.8255 12.2059 11.9721 11.3884C12.0338 11.0493 12.3588 10.8259 12.698 10.8843C13.038 10.9451 13.2638 11.2701 13.203 11.6101C12.9638 12.9376 11.9121 13.9909 10.5855 14.2326C10.548 14.2393 10.5096 14.2426 10.473 14.2426Z"
        fill="#212121"
      />
      <mask
        id="mask0_200_3873"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="4"
        width="15"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66699 4.35449H15.0722V16.1029H1.66699V4.35449Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_200_3873)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.54574 16.1029C5.41074 16.1029 5.27491 16.0588 5.15991 15.9696C3.75074 14.8629 2.55991 13.2404 1.71824 11.2788C1.64991 11.1204 1.64991 10.9421 1.71824 10.7846C2.56908 8.8146 3.76741 7.18376 5.18408 6.0696C8.07241 3.78376 11.9174 3.77626 14.8349 6.08626C15.1057 6.30043 15.1516 6.69376 14.9374 6.9646C14.7224 7.23376 14.3307 7.28126 14.0591 7.06626C11.5874 5.1096 8.40324 5.11626 5.95824 7.05127C4.76158 7.99293 3.73408 9.3646 2.97574 11.0329C3.72658 12.6913 4.74491 14.0546 5.93241 14.9863C6.20408 15.1996 6.25074 15.5929 6.03741 15.8638C5.91408 16.0204 5.73074 16.1029 5.54574 16.1029Z"
          fill="#212121"
        />
      </g>
      <mask
        id="mask1_200_3873"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="7"
        width="12"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.26465 7.78418H18.3334V17.741H7.26465V7.78418Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_200_3873)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 17.741C9.21934 17.741 8.44268 17.6144 7.69268 17.3652C7.36518 17.256 7.18768 16.9019 7.29684 16.5744C7.40601 16.246 7.75851 16.0719 8.08768 16.1785C8.71018 16.386 9.35351 16.491 10.0002 16.491C12.8568 16.491 15.4677 14.456 17.0252 11.0302C16.6452 10.1977 16.2027 9.44352 15.7077 8.78518C15.5002 8.50935 15.5552 8.11685 15.831 7.90935C16.106 7.70185 16.4985 7.75852 16.706 8.03352C17.3093 8.83435 17.8393 9.76018 18.2818 10.7819C18.351 10.9402 18.351 11.1202 18.2818 11.2777C16.5352 15.3252 13.4393 17.741 10.0002 17.741Z"
          fill="#212121"
        />
      </g>
      <mask
        id="mask2_200_3873"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="3"
        width="16"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.80371 3.83398H17.1983V18.2282H2.80371V3.83398Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_200_3873)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.4285 18.2278C3.2685 18.2278 3.1085 18.167 2.98684 18.0445C2.74267 17.8003 2.74267 17.4053 2.98684 17.1611L16.1318 4.01613C16.376 3.77197 16.771 3.77197 17.0152 4.01613C17.2593 4.2603 17.2593 4.65613 17.0152 4.9003L3.87017 18.0445C3.7485 18.167 3.5885 18.2278 3.4285 18.2278Z"
          fill="#212121"
        />
      </g>
    </svg>
  );
};

export default CloseEyeIcon;
