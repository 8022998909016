interface NoseButtonsProps {
  src: string;
  title: string;
  onClick: (title: string) => void;
}

const NoseButtons = ({ src, title, onClick }: NoseButtonsProps) => {
  return (
    <div className="nose" onClick={() => onClick(title)}>
      <img src={src} alt="nose-button" />
      <div className="content">
        <span>{title}</span>
      </div>
    </div>
  );
};

export default NoseButtons;
