import { InputFieldProps } from '../../../types/InputField';

interface OtpInputProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const OtpInput = ({ name, value, onChange, onKeyUp }: OtpInputProps) => {
  return (
    <div className="otp-input-field">
      <input
        id={name}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        maxLength={1}
        className="OtpInput"
      />
    </div>
  );
};

export default OtpInput;
