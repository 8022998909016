import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProfileSection from '../../../../components/organisms/description-sections/profile-update-section/ProfileSection';
import { GENDER_DESCRIPTION, GENDER_TITLE } from '../constants';
import MaleIcon from '../../../../assests/icons/MaleIcon';
import FemaleIcon from '../../../../assests/icons/FemaleIcon';

interface GenderProps {
  onDataChange?: (gender: 'male' | 'female') => void;
  title?: string;
  subTitle?: string;
}

const Gender = ({ onDataChange, title, subTitle }: GenderProps) => {
  const [selectedGender, setSelectedGender] = useState<'male' | 'female' | null>('male');

  const handleGenderSelect = (gender: 'male' | 'female') => {
    setSelectedGender(gender);
    if (onDataChange) {
      onDataChange(gender);
    }
  };

  return (
    <Container fluid className="d-flex align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center left-tab w-100"
        >
          <div>
            <ProfileSection
              title={title ?? GENDER_TITLE}
              description={subTitle ?? GENDER_DESCRIPTION}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                alignItems: 'center',
                marginTop: '30px',
              }}
            >
              <MaleIcon
                isSelected={selectedGender === 'male'}
                onSelect={() => handleGenderSelect('male')}
              />
              <FemaleIcon
                isSelected={selectedGender === 'female'}
                onSelect={() => handleGenderSelect('female')}
              />
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column align-items-center justify-content-center w-100"
        ></Col>
      </Row>
    </Container>
  );
};

export default Gender;
