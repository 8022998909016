import { Alignment } from '../../../types/textTypes';

interface TitleProps {
  text: string;
  alignment?: Alignment;
  marginTop?: string;
  fontSize?: string;
  smallMobileFontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  smallMobileLineHeight?: string;
  fontColor?: string;
  letterSpacing?: string;
  width?: string;
  smallMobileMarginTop?: string;
  smallMobileWidth?: string;
}

const Title = ({
  text,
  alignment,
  marginTop,
  fontSize,
  fontWeight,
  lineHeight,
  fontColor,
  letterSpacing,
  smallMobileFontSize,
  smallMobileLineHeight,
  width,
  smallMobileMarginTop,
  smallMobileWidth,
}: TitleProps) => {
  const isSmallScreen = window.innerWidth <= 400;
  const appliedFontSize = isSmallScreen && smallMobileFontSize ? smallMobileFontSize : fontSize;
  const appliedLineHeight =
    isSmallScreen && smallMobileFontSize ? smallMobileLineHeight : lineHeight;
  const appliedMarginTop = isSmallScreen && smallMobileMarginTop ? smallMobileMarginTop : marginTop;
  const appliedWidth = isSmallScreen && smallMobileWidth ? smallMobileWidth : width;

  return (
    <h1
      style={{
        textAlign: alignment,
        marginTop: appliedMarginTop,
        fontSize: appliedFontSize,
        fontWeight: fontWeight,
        lineHeight: appliedLineHeight,
        color: fontColor,
        letterSpacing: letterSpacing,
        width: appliedWidth,
      }}
      className="main-text"
    >
      {text}
    </h1>
  );
};

export default Title;
