import logo from '../../../assests/images/babara-logo.png';

interface LogoProps {
  width: string;
  height: string;
}
const Logo = ({ width, height }: LogoProps) => {
  const isLargerMobileScreen = window.innerWidth >= 768;
  const appliedWidth = isLargerMobileScreen && isLargerMobileScreen ? '80px' : width;
  const appliedHeight = isLargerMobileScreen && isLargerMobileScreen ? '80px' : height;

  return <img src={logo} alt="" width={appliedWidth} height={appliedHeight} />;
};

export default Logo;
