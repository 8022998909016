import React from 'react';
import Male from '../images/male-b.png';
import MaleWhite from '../images/male-w.png';

interface MaleIconProps {
  isSelected: boolean;
  onSelect: () => void;
}

const MaleIcon = ({ isSelected, onSelect }: MaleIconProps) => {
  const isSmallScreen = window.innerWidth <= 400;
  const appliedWidthHeight = isSmallScreen && isSmallScreen ? '150px' : '199px';
  const appliedFontSize = isSmallScreen && isSmallScreen ? '20px' : '24px';
  return (
    <div
      onClick={onSelect}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: appliedWidthHeight,
        height: appliedWidthHeight,
        borderRadius: '1000px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#000' : '#fff',
        color: isSelected ? '#fff' : '#000',
        transition: 'background-color 0.3s, color 0.3s',
      }}
    >
      <img
        width="60"
        height="60"
        src={isSelected ? MaleWhite : Male}
        alt="Male Icon"
        style={{
          transition: 'filter 0.3s',
        }}
      />
      <span
        style={{
          fontSize: appliedFontSize,
          fontWeight: '700',
          marginTop: '0.5rem',
          lineHeight: '38.4px',
        }}
      >
        Male
      </span>
    </div>
  );
};

export default MaleIcon;
