import { Container, Row, Col } from 'react-bootstrap';
import { ButtonText } from '../../../components/types/ButtonTypes';
import { useState } from 'react';
import useNavigateTo from '../../../app/hooks/useNavigateTo';
import { ACTION_CLASS } from '../../../components/organisms/action-section';
import { register } from '../../../slices/auth/thunk/register';
import { useAppDispatch } from '../../../app/hooks/useAppDispatch';
import BackButton from '../../../components/molecules/Buttons/BackButton/BackButton';
import SignUpDescription from '../../../components/organisms/description-sections/signup-description/SignUpDescription';
import AuthSection from '../../../components/organisms/auth-sections/AuthSection';
import TermsCheck from '../../../components/molecules/TextBlock/Terms/TermsCheck';
import LoginPrompt from '../../../components/molecules/TextBlock/AccountPrompt/SignUpPrompt/LoginPrompt';
import Divider from '../../../components/molecules/TextBlock/Divider/Divider';
import Action from '../../../components/organisms/action-section/Action';
import SocialLogin from '../../../components/organisms/login-sections/FederatedLoginSection/SocialLogin';
import useToast, { ToastType } from '../../../app/hooks/useToast';
import { GENERIC_WARNINGS, getSignUpErrorMessage } from '../../../app/utils/errorMessages';
interface SignUpProps {}

const SignUp = () => {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigateTo();
  const { showToast } = useToast();
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleTermsCheckboxChange = (checked: boolean) => {
    setIsTermsChecked(checked);
    console.log('Terms checkbox is checked:', checked); // You can perform other actions here as needed
  };

  const handleAuthSectionChange = (
    values: { email: string; password: string },
    isValid: boolean,
  ) => {
    setFormValues(values);
    setIsFormValid(isValid);
  };

  const handleSignUp = async () => {
    if (isFormValid && isTermsChecked) {
      try {
        const actionResult = await dispatch(register(formValues)).unwrap();
        const emailEncoded = encodeURIComponent(formValues.email);
        showToast(ToastType.SUCCESS, 'Registered successfully!');
        navigateTo(`/account-verify?email=${emailEncoded}`);
      } catch (error: any) {
        const errorMessage = getSignUpErrorMessage(error);
        showToast(ToastType.ERROR, errorMessage);
      }
    } else if (!isTermsChecked) {
      showToast(ToastType.WARNING, GENERIC_WARNINGS.CHECKBOX);
    }
  };

  return (
    <div>
      <BackButton />
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Row className="text-center w-100">
          <Col
            xs={12}
            md={6}
            className="d-flex align-items-center justify-content-center left-tab w-100"
          >
            <div>
              <SignUpDescription />
              <AuthSection onFormChange={handleAuthSectionChange} />
              <TermsCheck onCheckboxChange={handleTermsCheckboxChange} />
              <LoginPrompt />
              <Divider />
              <Action
                buttonName={ButtonText.SignUp}
                onClick={handleSignUp}
                className={ACTION_CLASS.WEB}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-center justify-content-center w-100"
          >
            <SocialLogin marginTop="15px" />
            <Action
              buttonName={ButtonText.SignUp}
              onClick={handleSignUp}
              className={ACTION_CLASS.MOBILE_BOTTOM}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
