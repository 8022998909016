import { useAppSelector } from "../../app/hooks/useAppSelector";
import { RootState } from "../../app/store";

export const useLoginTimeStamp = () => {
    return useAppSelector((state: RootState) => state.auth.loginTimestamp);
  };
export const useUserOnBoarded = () => {
  return useAppSelector((state: RootState) => state.auth.userOnboarded);
}

export const useAccessToken = () => {
  return useAppSelector((state: RootState) => state.auth.accessToken);
}

export const useUserprofile = () => {
  return useAppSelector((state: RootState) => state.auth.userProfile);
}

export const useUserEmail = () => {
  return useAppSelector((state: RootState) => state.auth.user?.email);
}