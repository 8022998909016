import { useState } from 'react';
import Title from '../../../atoms/texts/title-text/Title';
import Checkbox from '../../TextFields/Checkbox/Checkbox';
import {
  ALIGNMENT,
  ALIGNMENT_LINK,
  FONT_SIZE,
  FONT_WEIGHT,
  FONT_WEIGHT_LINK,
  LETTER_SPACING,
  LINE_HEIGHT,
  LOGIN_URL,
  SMALL_MOBILE_FONT_SIZE,
  SMALL_MOBILE_LINE_HEIGHT,
  TITLE,
  TITLE_LINK,
} from '.';
import { Link } from 'react-router-dom';

interface RememberCheckProps {
  onCheckboxChange: (checked: boolean) => void;
}

const RememberCheck = ({ onCheckboxChange }: RememberCheckProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
    onCheckboxChange(checked);
  };
  return (
    <div
      className="terms-check"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '2.5px',
      }}
    >
      <div className="center-baseline">
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
        <Title
          text={TITLE}
          alignment={ALIGNMENT}
          fontSize={FONT_SIZE}
          fontWeight={FONT_WEIGHT}
          lineHeight={LINE_HEIGHT}
          letterSpacing={LETTER_SPACING}
          smallMobileFontSize={SMALL_MOBILE_FONT_SIZE}
          smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT}
        />
      </div>

      <Link to={LOGIN_URL}>
        <Title
          text={TITLE_LINK}
          alignment={ALIGNMENT_LINK}
          fontSize={FONT_SIZE}
          fontWeight={FONT_WEIGHT_LINK}
          lineHeight={LINE_HEIGHT}
          letterSpacing={LETTER_SPACING}
          smallMobileFontSize={SMALL_MOBILE_FONT_SIZE}
          smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT}
        />
      </Link>
    </div>
  );
};

export default RememberCheck;
