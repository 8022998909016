import { PRODUCT_NAME } from "../../../../config/description";
import { Alignment } from "../../../types/textTypes";

export const TITLE = `Remember me`
export const ALIGNMENT = Alignment.LEFT;
export const FONT_SIZE = '18px';
export const FONT_WEIGHT = '500';
export const LINE_HEIGHT = '28.8px';
export const LETTER_SPACING = '0.2px'
export const SMALL_MOBILE_FONT_SIZE = '12px';
export const SMALL_MOBILE_LINE_HEIGHT = '22px';

export const TITLE_LINK = `Forgot Password?`
export const ALIGNMENT_LINK = Alignment.LEFT;
export const FONT_WEIGHT_LINK = '600';
export const LOGIN_URL = '/forgot-password'