const ExpertIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42 13C42 10.2386 39.7614 8 37 8C34.2386 8 32 10.2386 32 13V35C32 37.7614 34.2386 40 37 40C39.7614 40 42 37.7614 42 35V13ZM28 23C28 20.2386 25.7614 18 23 18C20.2386 18 18 20.2386 18 23V35C18 37.7614 20.2386 40 23 40C25.7614 40 28 37.7614 28 35V23ZM14 33C14 30.2386 11.7614 28 9 28C6.23858 28 4 30.2386 4 33V35C4 37.7614 6.23858 40 9 40C11.7614 40 14 37.7614 14 35V33Z"
        fill="#7E6DFC"
      />
      <path
        d="M42 13C42 10.2386 39.7614 8 37 8C34.2386 8 32 10.2386 32 13V35C32 37.7614 34.2386 40 37 40C39.7614 40 42 37.7614 42 35V13ZM28 23C28 20.2386 25.7614 18 23 18C20.2386 18 18 20.2386 18 23V35C18 37.7614 20.2386 40 23 40C25.7614 40 28 37.7614 28 35V23ZM14 33C14 30.2386 11.7614 28 9 28C6.23858 28 4 30.2386 4 33V35C4 37.7614 6.23858 40 9 40C11.7614 40 14 37.7614 14 35V33Z"
        fill="#F28E2E"
      />
    </svg>
  );
};

export default ExpertIcon;
