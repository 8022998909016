import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CardDescription from '../../organisms/description-sections/card-description/CardDescription';
import Title from '../texts/title-text/Title';
import { Alignment } from '../../types/textTypes';
interface CardProps {
  backgroundImage: string;
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
}

const Card: React.FC<CardProps> = ({
  backgroundImage,
  title,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
    <div
      className="card-component"
      style={{ backgroundImage: `url(${backgroundImage})`, marginTop: '20px' }}
    >
      <div className="card-content">
        <div className="text-column">
          <Container fluid>
            <Row className="justify-content-left text-left align-left">
              <Col xs={8}>
                <Title
                  text={title}
                  alignment={Alignment.LEFT}
                  marginTop={'15px'}
                  fontSize={'26px'}
                  fontWeight={'700'}
                  width={'143px'}
                />
                <span>{description}</span>
              </Col>
              <Col xs={6}></Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Card;
