import { toast, ToastOptions } from 'react-toastify';

export enum ToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
};

const useToast = () => {
  const showToast = (type: ToastType, message: string, options?: ToastOptions) => {
    const config: ToastOptions = {
      position: 'top-right',
      autoClose: 3000, // 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
      ...options,
    };

    switch (type) {
      case 'success':
        toast.success(message, config);
        break;
      case 'error':
        toast.error(message, config);
        break;
      case 'warning':
        toast.warning(message, config);
        break;
      case 'info':
        toast.info(message, config);
        break;
      default:
        break;
    }
  };

  return { showToast };
};

export default useToast;
