import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProfileSection from '../../../../components/organisms/description-sections/profile-update-section/ProfileSection';
import DateSection from '../../../../components/organisms/profile-section/date-section/DateSection';
import { SUB_TITLE, TITLE } from './contants';

interface BirthDateProps {
  onSubmit: (data: { birthDate: Date | null }, errors: { birthDate?: string }) => void;
  title?: string;
  subTitle?: string;
}

const BirthDate = ({ onSubmit, title, subTitle }: BirthDateProps) => {
  const handleDataChange = (values: { birthDate: Date | null }, errors: { birthDate?: string }) => {
    onSubmit(values, errors);
  };

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center left-tab w-100 flex-column"
        >
          <ProfileSection title={title ?? TITLE} description={subTitle ?? SUB_TITLE} />
          <DateSection onFormChange={handleDataChange} />
        </Col>
      </Row>
    </Container>
  );
};

export default BirthDate;
