import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SocialLogin from '../../../components/organisms/login-sections/FederatedLoginSection/SocialLogin';
import BackButton from '../../../components/molecules/Buttons/BackButton/BackButton';
import Action from '../../../components/organisms/action-section/Action';
import { ButtonText } from '../../../components/types/ButtonTypes';
import Divider from '../../../components/molecules/TextBlock/Divider/Divider';
import AuthSection from '../../../components/organisms/auth-sections/AuthSection';
import RememberCheck from '../../../components/molecules/TextBlock/Remember/RememberCheck';
import { ACTION_CLASS } from '../../../components/organisms/action-section';
import { useAppDispatch } from '../../../app/hooks/useAppDispatch';
import { login } from '../../../slices/auth/thunk/login';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { hideLoader, showLoader } from '../../../slices/loader';
import { LoaderTitleTypes } from '../../../components/types/loaderTitleTypes';
import LoginDescription from '../../../components/organisms/description-sections/login-description/LoginDescription';
import { getUser } from '../../../slices/auth/thunk/get-user';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authSectionRef = useRef<{
    submitForm: () => Promise<void>;
    getErrors: () => Record<string, string>;
  }>(null);

  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [isRemember, setIsRemember] = useState(false);

  const handleTermsCheckboxChange = (checked: boolean) => {
    setIsRemember(checked);
  };

  const handleAuthSectionChange = (values: { email: string; password: string }) => {
    setFormValues(values);
  };

  const handleLogin = async () => {
    if (authSectionRef.current) {
      await authSectionRef.current.submitForm();
    }

    const errors = authSectionRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      return;
    }

    dispatch(showLoader(LoaderTitleTypes.SIGNIN));
    const actionResult = await dispatch(login(formValues));

    try {
      if (login.fulfilled.match(actionResult)) {
        navigate(ROUTES.DASHBOARD);
      } else {
        console.error('Login failed:', actionResult.error.message);
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div>
      <BackButton />
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Row className="text-center w-100">
          <Col
            xs={12}
            md={6}
            className="d-flex align-items-center justify-content-center left-tab w-100"
          >
            <div>
              <LoginDescription />
              <AuthSection ref={authSectionRef} onFormChange={handleAuthSectionChange} />
              <RememberCheck onCheckboxChange={handleTermsCheckboxChange} />
              <Divider />
              <Action
                buttonName={ButtonText.Login}
                onClick={handleLogin}
                className={ACTION_CLASS.WEB}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-center justify-content-center w-100"
          >
            <SocialLogin marginTop="15px" />
            <Action
              buttonName={ButtonText.Login}
              onClick={handleLogin}
              className={ACTION_CLASS.MOBILE_BOTTOM}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
