import { ACTION_CLASS } from '.';
import PrimaryButton from '../../molecules/Buttons/PrimaryButton/PrimaryButton';
import { ButtonText, ButtonTheme } from '../../types/ButtonTypes';

interface ActionProps {
  buttonName: ButtonText;
  onClick: () => void;
  className?: ACTION_CLASS;
}

const Action = ({ buttonName, onClick, className }: ActionProps) => {
  return (
    <div className={`${className}`}>
      <div className="action">
        <PrimaryButton
          text={buttonName}
          onClick={() => onClick()}
          theme={ButtonTheme.Dark}
          showIcon={false}
        />
      </div>
    </div>
  );
};

export default Action;
