import { useFormik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import * as Yup from 'yup';
import InputField from '../../molecules/TextFields/InputField/InputField';
import { InputFormType, InputState } from '../../types/InputField';
import CloseEyeIcon from '../../../assests/icons/CloseEyeIcon';
import PasswordIcon from '../../../assests/icons/PasswordIcon';
import {
  CONFIRM_NEW_PW_LABEL,
  CONFIRM_NEW_PW_NAME,
  CONFIRM_NEW_PW_PLACEHOLDER,
  NEW_PW_LABEL,
  NEW_PW_NAME,
  NEW_PW_PLACEHOLDER,
} from '.';

interface ResetPasswordSectionProps {
  onFormChange: (values: { newPassword: string; confirmPassword: string }) => void;
}

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().min(6, 'Password too short').required('Required'),
  confirmPassword: Yup.string()
    .min(6, 'Password too short')
    .required('Required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

const ResetPasswordSection = forwardRef(({ onFormChange }: ResetPasswordSectionProps, ref) => {
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values: any) => {
      console.log(values);
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await formik.submitForm();
    },
    getErrors: () => formik.errors,
  }));

  useEffect(() => {
    onFormChange(formik.values);
  }, [formik.values, onFormChange]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputField
        name={NEW_PW_NAME}
        label={NEW_PW_LABEL}
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Password}
        state={
          formik.touched.newPassword && formik.errors.newPassword
            ? InputState.Error
            : InputState.Default
        }
        leftIcon={<PasswordIcon />}
        rightIcon={<CloseEyeIcon />}
        placeholder={NEW_PW_PLACEHOLDER}
        showRightIcon={true}
        error={
          formik.touched.newPassword && formik.errors.newPassword ? formik.errors.newPassword : ''
        }
      />
      <InputField
        name={CONFIRM_NEW_PW_NAME}
        label={CONFIRM_NEW_PW_LABEL}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Password}
        state={
          formik.touched.confirmPassword && formik.errors.confirmPassword
            ? InputState.Error
            : InputState.Default
        }
        leftIcon={<PasswordIcon />}
        rightIcon={<CloseEyeIcon />}
        placeholder={CONFIRM_NEW_PW_PLACEHOLDER}
        showRightIcon={true}
        error={
          formik.touched.confirmPassword && formik.errors.confirmPassword
            ? formik.errors.confirmPassword
            : ''
        }
      />
    </form>
  );
});

export default ResetPasswordSection;
