import React from 'react';

interface DateButtonProps {
  day?: string;
  date?: string;
  isSelected: boolean;
}

const DateButton: React.FC<DateButtonProps> = ({ day, date, isSelected }) => {
  return (
    <div
      className="date-button"
      style={{
        backgroundColor: isSelected ? 'black' : 'white',
      }}
    >
      <span className="day" style={{ color: 'gray' }}>
        {day}
      </span>
      <span className="date" style={{ color: isSelected ? 'white' : 'black' }}>
        {date}
      </span>
    </div>
  );
};

export default DateButton;
