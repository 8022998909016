import {
  LETTER_SPACING_DESCRIPTION,
  MOBILE_MARGIN_TOP,
  SMALL_MOBILE_FONT_SIZE_DESCRIPTION,
  SMALL_MOBILE_FONT_SIZE_HEADER,
  SMALL_MOBILE_LINE_HEIGHT_DESCRIPTION,
  SMALL_MOBILE_LINE_HEIGHT_HEADER,
  SMALL_MOBILE_MARGIN_TOP,
  SMALL_MOBILE_WIDTH_DESCRIPTION,
} from '.';
import Title from '../../../atoms/texts/title-text/Title';
import { Alignment } from '../../../types/textTypes';

interface TextBlockProps {
  textTitle: string;
  textDesc: string;
  alignment?: Alignment;
  marginTopTitle?: string;
  marginTopDesc?: string;
  fontSizeTitle?: string;
  fontSizeDesc?: string;
  fontWeightTitle?: string;
  fontWeightDesc?: string;
  lineHeightTitle?: string;
  lineHeightDesc?: string;
  widthDesc?: string;
  smallMarginBottom?: string;
  widthTitle?: string;
  considerLineHeight?: boolean;
  smallMobileDescWidth?: string;
}

const TextBlock = ({
  textTitle,
  textDesc,
  alignment,
  marginTopDesc,
  marginTopTitle,
  fontSizeDesc,
  fontSizeTitle,
  fontWeightDesc,
  fontWeightTitle,
  lineHeightTitle,
  lineHeightDesc,
  widthDesc,
  smallMarginBottom,
  widthTitle,
  considerLineHeight,
  smallMobileDescWidth,
}: TextBlockProps) => {
  const isSmallScreen = window.innerWidth <= 400;
  const isLargerMobileScreen = window.innerWidth >= 401 && window.innerWidth <= 768;
  const appliedMarginBottom = isSmallScreen && smallMarginBottom ? smallMarginBottom : '';
  const appliedMarginTopTitle = isLargerMobileScreen ? MOBILE_MARGIN_TOP : marginTopTitle;
  const appliedLineHeightTitle = isLargerMobileScreen
    ? considerLineHeight
      ? lineHeightTitle
      : SMALL_MOBILE_LINE_HEIGHT_HEADER
    : lineHeightTitle;
  const appliedLineHeightDesc = isLargerMobileScreen
    ? SMALL_MOBILE_LINE_HEIGHT_DESCRIPTION
    : lineHeightDesc;

  return (
    <div className="text-block" style={{ marginBottom: appliedMarginBottom }}>
      <Title
        text={textTitle}
        alignment={alignment}
        marginTop={appliedMarginTopTitle}
        fontSize={fontSizeTitle}
        fontWeight={fontWeightTitle}
        lineHeight={appliedLineHeightTitle}
        smallMobileFontSize={SMALL_MOBILE_FONT_SIZE_HEADER}
        smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT_HEADER}
        smallMobileMarginTop={SMALL_MOBILE_MARGIN_TOP}
        width={widthTitle}
      />
      <Title
        text={textDesc}
        alignment={alignment}
        marginTop={marginTopDesc}
        fontSize={fontSizeDesc}
        fontWeight={fontWeightDesc}
        lineHeight={lineHeightDesc}
        smallMobileFontSize={SMALL_MOBILE_FONT_SIZE_DESCRIPTION}
        smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT_DESCRIPTION}
        width={widthDesc}
        smallMobileWidth={
          smallMobileDescWidth ? smallMobileDescWidth : SMALL_MOBILE_WIDTH_DESCRIPTION
        }
        letterSpacing={LETTER_SPACING_DESCRIPTION}
      />
    </div>
  );
};

export default TextBlock;
