import Title from '../../../atoms/texts/title-text/Title';
import {
  ButtonText,
  ButtonType,
  ButtonSize,
  SocialMediaPlatform,
  ButtonTheme,
  MiddleIcons,
} from '../../../types/ButtonTypes';

export interface PrimaryButtonProps {
  buttonType?: ButtonType;
  size?: ButtonSize;
  theme?: ButtonTheme;
  socialMediaPlatform?: SocialMediaPlatform;
  disabled?: boolean;
  showIcon?: boolean;
  showText?: boolean;
  text?: ButtonText;
  onClick?: () => void;
  url?: string;
  marginBottom?: string;
  showIconMiddle?: boolean;
  middleIcon?: MiddleIcons;
  buttonTextWidth?: string;
  marginTop?: string;
}

const PrimaryButton = ({
  buttonType = ButtonType.Pill,
  size = ButtonSize.Medium,
  theme = ButtonTheme.Light,
  socialMediaPlatform = SocialMediaPlatform.Google,
  disabled = false,
  showIcon = true,
  showText = true,
  showIconMiddle = false,
  middleIcon,
  text = ButtonText.Google,
  onClick,
  marginBottom,
  buttonTextWidth = '262px',
  marginTop,
}: PrimaryButtonProps) => {
  const buttonClass = `social-media-button ${buttonType} ${size} ${theme}`;
  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
      style={{ marginBottom: marginBottom, marginTop: marginTop }}
    >
      {showIcon && <span className={`icon ${socialMediaPlatform}`} />}
      {showIconMiddle && <span className={`mid-icon ${middleIcon}`} />}
      {showText && (
        <span className="button-text" style={{ width: buttonTextWidth }}>
          {text}
        </span>
      )}
    </button>
  );
};

export default PrimaryButton;
