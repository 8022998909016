interface PasswordIconProps {}

const PasswordIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1018 9.00013C13.7568 9.00013 13.4768 8.72013 13.4768 8.37513V6.58596C13.4768 4.8393 12.056 3.41846 10.3093 3.41846H10.296C9.45181 3.41846 8.66098 3.74346 8.06348 4.33596C7.46181 4.93096 7.12931 5.72513 7.12598 6.5718V8.37513C7.12598 8.72013 6.84598 9.00013 6.50098 9.00013C6.15598 9.00013 5.87598 8.72013 5.87598 8.37513V6.58596C5.88098 5.38596 6.34514 4.27846 7.18264 3.44846C8.02098 2.61763 9.12764 2.1368 10.3118 2.16846C12.7451 2.16846 14.7268 4.15013 14.7268 6.58596V8.37513C14.7268 8.72013 14.4468 9.00013 14.1018 9.00013Z"
        fill="#212121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11801 8.94043C5.71967 8.94043 4.58301 10.0771 4.58301 11.4754V15.0496C4.58301 16.4479 5.71967 17.5846 7.11801 17.5846H13.4855C14.883 17.5846 16.0205 16.4479 16.0205 15.0496V11.4754C16.0205 10.0771 14.883 8.94043 13.4855 8.94043H7.11801ZM13.4855 18.8346H7.11801C5.03051 18.8346 3.33301 17.1371 3.33301 15.0496V11.4754C3.33301 9.38793 5.03051 7.69043 7.11801 7.69043H13.4855C15.573 7.69043 17.2705 9.38793 17.2705 11.4754V15.0496C17.2705 17.1371 15.573 18.8346 13.4855 18.8346Z"
        fill="#212121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3018 14.8127C9.95676 14.8127 9.67676 14.5327 9.67676 14.1877V12.3369C9.67676 11.9919 9.95676 11.7119 10.3018 11.7119C10.6468 11.7119 10.9268 11.9919 10.9268 12.3369V14.1877C10.9268 14.5327 10.6468 14.8127 10.3018 14.8127Z"
        fill="#212121"
      />
    </svg>
  );
};

export default PasswordIcon;
