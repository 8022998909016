import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, initialState, LoginResponse, CognitoUser, RegisterResponse, UserProfileResponse, UserProfile } from './types';
import { STATE_KEY } from './constants';
import { login } from './thunk/login';
import { register } from './thunk/register';
import { googleLogin } from './thunk/googleLogin';
import { setProfileUpdate as setProfileUpdateReducer} from './reducers';
import { getUser } from './thunk/get-user';
const authSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    logout: (state) => {
      state.user = undefined;
      state.refreshToken = undefined;
      state.accessToken = undefined;
      state.idToken = undefined;
      state.loginTimestamp = undefined;
    },
    setProfileUpdate: setProfileUpdateReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state: AuthState, action: PayloadAction<LoginResponse>) => {
      state.refreshToken = action.payload.data.refreshToken.token;
      state.accessToken = action.payload.data.accessToken.jwtToken;
      state.idToken = action.payload.data.idToken.jwtToken;
      state.user = {
        id: parseInt(action.payload.data.idToken.payload.sub, 10),
        email: action.payload.data.idToken.payload.email || '',
      };
      state.loginTimestamp = Date.now();
    });
    builder.addCase(register.fulfilled, (state: AuthState, action: PayloadAction<RegisterResponse>) => {
      const { user, userConfirmed } = action.payload.data;
        if (state.registeredUser) {
          state.registeredUser.user = user;
          state.registeredUser.userConfirmed = userConfirmed;
      }
    });
    builder.addCase(googleLogin.fulfilled, (state: AuthState, action: PayloadAction<LoginResponse>) => {
      state.refreshToken = action.payload.data.refreshToken.token;
      state.accessToken = action.payload.data.accessToken.jwtToken;
      state.idToken = action.payload.data.idToken.jwtToken;
      state.user = {
        id: parseInt(action.payload.data.idToken.payload.sub, 10),
        email: action.payload.data.idToken.payload.email || '',
      };
      state.loginTimestamp = Date.now();
    });
    builder.addCase(getUser.fulfilled, (state: AuthState, action: PayloadAction<UserProfile>) => {
      state.userProfile = action.payload;
    });
  },
});

export const { logout, setProfileUpdate } = authSlice.actions;
export default authSlice.reducer;
