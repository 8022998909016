import { useState } from 'react';
import Stepper from '../../components/molecules/Stepper/Stepper';
import BirthDate from './elements/birthdate/Birthdate';
import NickName from './elements/nick-name/Nickname';
import Gender from './elements/gender/Gender';
import Experience from './elements/experience/Experience';
import { FormErrors, FormValues } from './types';
import { useNavigate } from 'react-router-dom';
import { setProfileUpdate } from '../../slices/auth';
import { useAppDispatch } from '../../app/hooks/useAppDispatch';
import { updateProfile } from '../../slices/auth/thunk/update-profile';
import { ROUTES } from '../../config/routes';
import { formatBirthdate } from '../../app/utils/dateFormatter';
import { getUser } from '../../slices/auth/thunk/get-user';

interface Errors {
  birthDate?: string | undefined;
  nickname?: string | undefined;
  initials?: string | undefined;
}

const ProfileUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [stepData, setStepData] = useState<any>({});
  const [errors, setErrors] = useState<Errors>({
    birthDate: undefined,
    nickname: undefined,
    initials: undefined,
  });

  const handleDataChange = (step: number, data: any) => {
    setStepData((prevData: any) => ({
      ...prevData,
      [`step${step}`]: data,
    }));
  };

  const handleBirthDate = (data: { birthDate: Date | null }, errors: { birthDate?: string }) => {
    setStepData((prevData: any) => ({
      ...prevData,
      step0: { birthDate: data.birthDate },
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      birthDate: errors.birthDate,
    }));
  };

  const handleNickName = (data: FormValues, errors: FormErrors) => {
    setStepData((prevData: any) => ({
      ...prevData,
      step1: { nickname: data.nickname, initials: data.initials },
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      nickname: errors.nickname,
      initials: errors.initials,
    }));
  };

  const onContinue = async (step: number) => {
    switch (step) {
      case 0:
        return !errors.birthDate;
      case 1:
        return !errors.nickname && !errors.initials;
      case 2:
        return true;
      case 3:
        return !!stepData.step3;
      default:
        return false;
    }
  };

  const handleStepperComplete = async () => {
    const formattedDate = formatBirthdate(stepData.step0.birthDate);
    const actionResult = await dispatch(
      updateProfile({
        birthdate: formattedDate,
        nickname: stepData.step1.nickname,
        gender: stepData.step2,
        preferred_username: stepData.step1.initials,
        'custom:experience': stepData.step3,
        'custom:profile-update': 'true',
      }),
    );

    try {
      if (updateProfile.fulfilled.match(actionResult)) {
        dispatch(setProfileUpdate(true));
        navigate(ROUTES.SUCCESS + '?update-profile');
      } else {
        console.error('Profile update failed:', actionResult.error.message);
      }
    } catch (error) {
      console.error('Profile update failed:');
    }
  };

  return (
    <div>
      <Stepper
        steps={[
          <BirthDate onSubmit={handleBirthDate} />,
          <NickName onDataChange={handleNickName} />,
          <Gender onDataChange={data => handleDataChange(2, data)} />,
          <Experience onDataChange={data => handleDataChange(3, data)} />,
        ]}
        onContinue={onContinue}
        onComplete={handleStepperComplete}
      />
    </div>
  );
};

export default ProfileUpdate;
