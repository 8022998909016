import { Container, Row, Col } from 'react-bootstrap';
import Action from '../../../components/organisms/action-section/Action';
import { ButtonText } from '../../../components/types/ButtonTypes';
import { ACTION_CLASS } from '../../../components/organisms/action-section';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import MobileRoundedIcon from '../../../assests/icons/MobileRoundIcon';
import PasswordSuccessfullySection from '../../../components/organisms/description-sections/password-successsfully-section/PasswordSuccessfullySection';

const PasswordRestSuccessfully = () => {
  const navigation = useNavigate();

  const handleGoHome = () => {
    navigation(ROUTES.HOME);
  };

  return (
    <Container fluid className="d-flex vh-100 align-items-center justify-content-center">
      <Row className="text-center">
        <Col>
          <MobileRoundedIcon />
          <PasswordSuccessfullySection />
          <Action
            buttonName={ButtonText.GoHome}
            onClick={handleGoHome}
            className={ACTION_CLASS.WEB}
          />
          <Action
            buttonName={ButtonText.GoHome}
            onClick={handleGoHome}
            className={ACTION_CLASS.MOBILE_BOTTOM}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordRestSuccessfully;
