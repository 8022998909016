import { Container, Row, Col, Image } from 'react-bootstrap';
import Logo from '../../../components/atoms/logo/logo';
import SpinnerWrapper from '../../../components/atoms/spinner/spinner';

interface SplashProps {
  appName: string;
}

const Splash = ({ appName }: SplashProps) => {
  return (
    <Container fluid className="d-flex vh-100 align-items-center justify-content-center">
      <Row className="text-center">
        <Col>
          <Logo width="130" height="130" />
          <h1 className=" splash-text">{appName}</h1>
          <SpinnerWrapper color="#ffffff" marginTop="215px" />
        </Col>
      </Row>
    </Container>
  );
};

export default Splash;
