import { LoaderTitleTypes } from "../../components/types/loaderTitleTypes";

export interface LoaderState {
    isLoading: boolean;
    loaderTitle: LoaderTitleTypes | undefined;
  }
  
 export const initialState: LoaderState = {
    isLoading: false,
    loaderTitle: undefined,
  };