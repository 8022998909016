// loaderSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATE_KEY } from './constants';
import { initialState } from './types';
import { LoaderTitleTypes } from '../../components/types/loaderTitleTypes';

const loaderSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    showLoader: (state, action: PayloadAction<LoaderTitleTypes>) => {
      state.isLoading = true;
      state.loaderTitle = action.payload;
    },
    hideLoader: (state) => {
      state.isLoading = false;
      state.loaderTitle = undefined;
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
