import { useUsersTimeSlots } from '../../../../slices/user-management/selectors';
import TC1 from '../../../../assests/images/tc1.png';
import TC2 from '../../../../assests/images/tc2.png';
import TC3 from '../../../../assests/images/tc3.png';
import TC4 from '../../../../assests/images/tc4.png';
import TC5 from '../../../../assests/images/tc5.png';
import NextIcon from '../../../../assests/icons/NextIcon';

const images = [TC1, TC2, TC3, TC4, TC5];

interface SavedTimeSlotsProps {}

const SavedTimeSlots = () => {
  const userTimeSlots = useUsersTimeSlots() ?? [];
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    } else if (
      date.getDate() === tomorrow.getDate() &&
      date.getMonth() === tomorrow.getMonth() &&
      date.getFullYear() === tomorrow.getFullYear()
    ) {
      return 'Tomorrow';
    } else {
      return dateString; // Show the original date if it's not today or tomorrow
    }
  };

  return userTimeSlots.length > 0 ? (
    <div className="saved-ts">
      <span>Saved Timeslots</span>
      {userTimeSlots.map((slot, index) => (
        <div className="ts" key={index}>
          <img src={index < images.length ? images[index] : images[0]} alt="" />
          <div className="content">
            <span>
              {formatDate(slot.timeslots.timeSlot.date)}&nbsp;&nbsp;at&nbsp;&nbsp;
              {slot.timeslots.timeSlot.time}
            </span>
            <span className="name">
              {slot.timeslots.pleasureToMe === 'true'
                ? 'More pleasure to me'
                : `More Pleasure to ${slot.timeslots.nickName}`}
            </span>
          </div>
          <NextIcon />
        </div>
      ))}
    </div>
  ) : null;
};

export default SavedTimeSlots;
