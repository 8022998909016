import { Container, Row, Col } from 'react-bootstrap';
import ProfileSection from '../../../components/organisms/description-sections/profile-update-section/ProfileSection';
import { DT_SUB_TITLE, DT_TITLE } from './constants';
import { useTimeSlots } from '../../../slices/time-slots/selectors';
import DateButtonContainer from '../../../components/organisms/time-slot-sections/DateButtonContainer';

interface DateTimeProps {
  onDataChange?: (date: string, time?: string, tutorials?: string[]) => void;
}

const DateTime = ({ onDataChange }: DateTimeProps) => {
  const timeSlotData = useTimeSlots();

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center left-tab w-100 flex-column"
        >
          <ProfileSection title={DT_TITLE} description={DT_SUB_TITLE} />
          <DateButtonContainer timeSlotData={timeSlotData!} onChangeData={onDataChange} />
        </Col>
      </Row>
    </Container>
  );
};

export default DateTime;
