import { Modal } from 'react-bootstrap';
interface BaseModalProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  width?: string;
  height?: string;
  top?: string;
  left?: string;
  children: React.ReactNode;
  padding?: string;
  gap?: string;
  borderRadius?: string;
  opacity?: number;
}

const BaseModal = ({ show, children }: BaseModalProps) => {
  return (
    <Modal show={show} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default BaseModal;
