import React from 'react';
import { Form } from 'react-bootstrap';
interface CheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked = false, onChange }) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return <Form.Check type="checkbox" checked={checked} onChange={handleChange} label="" />;
};

export default Checkbox;
