interface ExperienceButtonProps {
  leftIcon: React.ReactNode;
  primaryText: string;
  secondaryText: string;
  checkedIcon: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
}

const ExperienceButton = ({
  leftIcon,
  primaryText,
  secondaryText,
  checkedIcon,
  isSelected,
  onClick,
}: ExperienceButtonProps) => {
  return (
    <div className="reusable-button" onClick={onClick}>
      <div className="button-icon-left">{leftIcon}</div>
      <div className="button-content">
        <div className="primary-text">{primaryText}</div>
        <div className="secondary-text">{secondaryText}</div>
      </div>
      {isSelected && <div className="button-icon-right">{checkedIcon}</div>}
    </div>
  );
};

export default ExperienceButton;
