interface MobileRoundedIconProps {}

const MobileRoundedIcon = () => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_105_3024)">
        <g clipPath="url(#clip0_105_3024)">
          <path
            d="M0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60Z"
            fill="#7E6DFC"
          />
          <path
            d="M0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60Z"
            fill="white"
          />
          <path
            d="M28.5 37.8C28.5 31.9194 28.5 28.9792 29.6444 26.7331C30.6511 24.7574 32.2574 23.1511 34.2331 22.1444C36.4792 21 39.4194 21 45.3 21H75.45C81.3306 21 84.2708 21 86.5169 22.1444C88.4926 23.1511 90.0989 24.7574 91.1056 26.7331C92.25 28.9792 92.25 31.9194 92.25 37.8V135.45C92.25 141.331 92.25 144.271 91.1056 146.517C90.0989 148.493 88.4926 150.099 86.5169 151.106C84.2708 152.25 81.3306 152.25 75.45 152.25H45.3C39.4194 152.25 36.4792 152.25 34.2331 151.106C32.2574 150.099 30.6511 148.493 29.6444 146.517C28.5 144.271 28.5 141.331 28.5 135.45V37.8Z"
            fill="#212121"
          />
          <path
            d="M31.5 36C31.5 31.7996 31.5 29.6994 32.3175 28.0951C33.0365 26.6839 34.1839 25.5365 35.5951 24.8175C37.1994 24 39.2996 24 43.5 24H77.25C81.4504 24 83.5506 24 85.1549 24.8175C86.5661 25.5365 87.7135 26.6839 88.4325 28.0951C89.25 29.6994 89.25 31.7996 89.25 36V137.25C89.25 141.45 89.25 143.551 88.4325 145.155C87.7135 146.566 86.5661 147.714 85.1549 148.433C83.5506 149.25 81.4504 149.25 77.25 149.25H43.5C39.2996 149.25 37.1994 149.25 35.5951 148.433C34.1839 147.714 33.0365 146.566 32.3175 145.155C31.5 143.551 31.5 141.45 31.5 137.25V36Z"
            fill="white"
          />
          <rect x="51" y="25.5059" width="19.5" height="6.00725" rx="3.00363" fill="#212121" />
          <g filter="url(#filter1_d_105_3024)">
            <path
              d="M46.5 67.5C46.5 60.0442 52.5442 54 60 54C67.4558 54 73.5 60.0442 73.5 67.5C73.5 74.9558 67.4558 81 60 81C52.5442 81 46.5 74.9558 46.5 67.5Z"
              fill="#7E6DFC"
            />
            <path
              d="M46.5 67.5C46.5 60.0442 52.5442 54 60 54C67.4558 54 73.5 60.0442 73.5 67.5C73.5 74.9558 67.4558 81 60 81C52.5442 81 46.5 74.9558 46.5 67.5Z"
              fill="#F6821F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.0003 68.6439C57.5547 68.6439 55.4097 70.0826 55.4097 71.722C55.4097 73.8314 58.8653 73.8314 60.0003 73.8314C61.1353 73.8314 64.5903 73.8314 64.5903 71.7083C64.5903 70.0758 62.4453 68.6439 60.0003 68.6439Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M59.9762 67.2761H59.9956C61.68 67.2761 63.05 65.9061 63.05 64.2217C63.05 62.538 61.68 61.168 59.9956 61.168C58.3112 61.168 56.9412 62.538 56.9412 64.2205C56.9356 65.8992 58.2962 67.2698 59.9762 67.2761Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_105_3024"
          x="0"
          y="-12"
          width="120"
          height="132"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-12" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.94902 0 0 0 0 0.556863 0 0 0 0 0.180392 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_105_3024" />
        </filter>
        <filter
          id="filter1_d_105_3024"
          x="26.5"
          y="38"
          width="75"
          height="75"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.494118 0 0 0 0 0.427451 0 0 0 0 0.988235 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_105_3024" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_105_3024"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_105_3024">
          <path
            d="M0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileRoundedIcon;
