import { useEffect, useState } from 'react';
import Logo from '../../components/atoms/logo/logo';
import { PRODUCT_NAME } from '../../config/description';
import Splash from '../Onboarding/splash/Splash';
import Welcome from '../Onboarding/welcome/Welcome';

interface HomeProps {}

const Home = () => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return <div className="body">{showSplash ? <Splash appName={PRODUCT_NAME} /> : <Welcome />}</div>;
};

export default Home;
