import React from 'react';
interface PrivacyTabProps {
  text: string;
}

const PrivacyTerms = ({}) => {
  const texts = [
    { id: 1, label: 'Privacy Policy' },
    { id: 2, label: 'Terms of Service' },
  ];
  return (
    <div className="privacy-tab">
      {texts.map((text, index) => (
        <React.Fragment key={text.id}>
          <span className="privacy-text">{text.label}</span>
          {index === 0 && <span className="dot">.</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PrivacyTerms;
