export enum ROUTES {
    HOME = '/',
    WELCOME = '/welcome',
    SIGNUP = '/signup',
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password',
    ACCOUNT_VERIFY = '/account-verify',
    RESET_PASSWORD = '/reset-password',
    RESET_PASSWORD_SUCCESSFULLY = '/password-successfully',
    DASHBOARD = '/dashboard',
    SUCCESS = '/success',
    CREATE_TIMESLOT = '/create-timeslot',
  }