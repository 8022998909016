import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import SectionTemplateSection from '../../organisms/description-sections/success-template-section/SuccessTemplateSection';
import Progress from '../../atoms/progress/Progress';
import Title from '../../atoms/texts/title-text/Title';
import { CREATE_TIME_SLOT_SUCCESS_TITLE, UPDATE_PROFILE_SUCCESS_TITLE } from './constants';
import {
  SMALL_MOBILE_FONT_SIZE_HEADER,
  SMALL_MOBILE_LINE_HEIGHT_HEADER,
  SMALL_MOBILE_MARGIN_TOP,
} from '../../molecules/TextBlock/MainHeadingDescription';
import { Alignment } from '../../types/textTypes';
import { useAppDispatch } from '../../../app/hooks/useAppDispatch';
import { getUser } from '../../../slices/auth/thunk/get-user';

const SuccessTemplate = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const title =
    search === '?update-profile' ? UPDATE_PROFILE_SUCCESS_TITLE : CREATE_TIME_SLOT_SUCCESS_TITLE;

  return (
    <Container fluid className="d-flex align-items-center justify-content-center vh-100">
      <Row className="text-center w-100 d-flex flex-column align-items-center">
        <Col
          xs={12}
          md={8}
          lg={6}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <SectionTemplateSection title={title} />
          <div style={{ width: '90%', maxWidth: '400px', margin: '20px 0' }}>
            <Progress />
          </div>
          <Title
            text={'This will just take a moment. Get ready to transform your journey!'}
            alignment={Alignment.CENTER}
            fontSize={'18px'}
            fontWeight={'400'}
            lineHeight={'25.2px'}
            smallMobileFontSize={SMALL_MOBILE_FONT_SIZE_HEADER}
            smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT_HEADER}
            smallMobileMarginTop={SMALL_MOBILE_MARGIN_TOP}
            width={'100%'}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessTemplate;
