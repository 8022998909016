import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../components/atoms/logo/logo';
import DashboardDescription from '../../components/organisms/description-sections/dashboard-description/DashboardDescription';
import PrimaryButton from '../../components/molecules/Buttons/PrimaryButton/PrimaryButton';
import { ButtonText, ButtonTheme, MiddleIcons } from '../../components/types/ButtonTypes';
import CardSection from '../../components/organisms/card-section/CardSection';
import { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks/useAppDispatch';
import { getUser } from '../../slices/auth/thunk/get-user';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import { getUserTimeSlot } from '../../slices/user-management/thunks/getUserTimeSlots';
import { useUsersTimeSlots } from '../../slices/user-management/selectors';
import { useUserprofile } from '../../slices/auth/selectors';
import SavedTimeSlots from '../../components/organisms/time-slot-sections/SavedTimeslots/SavedTimeslots';
import { hideLoader, showLoader } from '../../slices/loader';
import { LoaderTitleTypes } from '../../components/types/loaderTitleTypes';
import { useTimeSlots } from '../../slices/time-slots/selectors';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const dispatch = useAppDispatch();
  const userTimeSlots = useUsersTimeSlots();
  const userProfile = useUserprofile();

  useEffect(() => {
    const fetchData = async () => {
      if (userTimeSlots === undefined) {
        dispatch(showLoader(LoaderTitleTypes.DEFAULT));
        try {
          await dispatch(getUserTimeSlot()).unwrap();
        } catch (error) {
        } finally {
          dispatch(hideLoader());
        }
      }

      if (userProfile?.ProfileUpdate === 'false') {
        await dispatch(getUser());
      }
    };

    fetchData();
  }, []);

  const handleCreateTimeSlot = () => {
    console.log('Create Time Slot clicked');
  };

  return (
    <Container fluid className="d-flex align-items-center justify-content-center overflow-auto">
      <Row className="text-center w-100">
        <Col xs={12} md={8} lg={12} className="d-flex flex-column align-items-center w-100">
          <div className="mb-4 mt-5">
            <Logo width="42.4" height="42.4" />
          </div>
          <div className="mb-2">
            <DashboardDescription />
          </div>
          <div className="mb-2">
            <Link to={ROUTES.CREATE_TIMESLOT}>
              <PrimaryButton
                text={ButtonText.CreateTimeSlot}
                theme={ButtonTheme.Dark}
                showIcon={false}
                showIconMiddle={true}
                middleIcon={MiddleIcons.CLOCK}
                onClick={handleCreateTimeSlot}
                marginTop="30px"
                buttonTextWidth="unset"
              />
            </Link>
          </div>
          <SavedTimeSlots />
          <CardSection />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
