import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import useScrollToTop from './hooks/useScrollToTop';
import Home from '../pages/Home/Home';
import AccountVerify from '../pages/Onboarding/account-verify/AcoountVerify';
import ForgotPassword from '../pages/Onboarding/forgot-password/FrogotPassword';
import Login from '../pages/Onboarding/login/Login';
import ResetPassword from '../pages/Onboarding/reset-password/ResetPassword';
import Signup from '../pages/Onboarding/signup/Signup';
import Welcome from '../pages/Onboarding/welcome/Welcome';
import '../styles/main.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { updateRoutes } from '../slices/navigation/navigationSlice';
import PasswordRestSuccessfully from '../pages/Onboarding/reset-password/PasswordSucessfull';
import { ScrollRestoration } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../pages/Dashboard/Dashboard';
import { RootState } from './store';
import LoaderModal from '../components/molecules/Loader/LoaderModal';
import { LoaderTitleTypes } from '../components/types/loaderTitleTypes';
import { getLoaderTitle } from './utils/loaderUtils';
import useAutoLogout from './hooks/useAutoLogout';
import { useUserEmail, useUserOnBoarded, useUserprofile } from '../slices/auth/selectors';
import ProfileUpdate from '../pages/Profile/Profile';
import SuccessTemplate from '../components/templates/success/Sucess';
import { setProfileUpdate } from '../slices/auth';
import TimeSlot from '../pages/Timeslot/TimeSlot';
import { getUser } from '../slices/auth/thunk/get-user';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useUserprofile();
  const fakeProfileUpdated = useUserOnBoarded();
  const userEmail = useUserEmail();

  const { isLoading, loaderTitle } = useSelector((state: RootState) => state.loader);

  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    if (userEmail && user === undefined) {
      setLoadingUser(true);
      dispatch(getUser() as any).finally(() => setLoadingUser(false));
    }
  }, [userEmail, user, dispatch]);

  const profileUpdated = useMemo(() => {
    if (!user) return false;
    return user.ProfileUpdate ? JSON.parse(user.ProfileUpdate) : fakeProfileUpdated || false;
  }, [user, fakeProfileUpdated]);

  useEffect(() => {
    dispatch(updateRoutes(location.pathname));
  }, [location.pathname, dispatch]);

  useAutoLogout();

  return (
    <div className="app">
      <ToastContainer />
      {(isLoading || loadingUser) && (
        <LoaderModal
          show={true}
          onHide={() => {}}
          loaderTitle={loaderTitle || LoaderTitleTypes.DEFAULT}
        />
      )}

      {!loadingUser && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/account-verify" element={<AccountVerify />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/password-successfully" element={<PasswordRestSuccessfully />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>{!profileUpdated ? <ProfileUpdate /> : <Dashboard />}</ProtectedRoute>
            }
          />
          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <SuccessTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-timeslot"
            element={
              <ProtectedRoute>
                <TimeSlot />
              </ProtectedRoute>
            }
          />
        </Routes>
      )}
    </div>
  );
}

export default App;
