import * as Yup from 'yup';
import { useFormik } from 'formik';
import DatePicker from '../../../molecules/TextFields/Datepicker/Datepicker';
import { InputFormType, InputState } from '../../../types/InputField';
import GmailIcon from '../../../../assests/icons/GmailIcon';
import { useEffect } from 'react';

interface DateSectionProps {
  onFormChange: (values: { birthDate: Date | null }, errors: { birthDate?: string }) => void;
}

const today = new Date();
const minAllowedDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

const validationSchema = Yup.object().shape({
  birthDate: Yup.date()
    .required('Date of birth is required')
    .max(minAllowedDate, 'You must be at least 18 years old'),
});

const DateSection = ({ onFormChange }: DateSectionProps) => {
  const formik = useFormik({
    initialValues: { birthDate: undefined },
    validationSchema,
    onSubmit: values => {},
  });

  useEffect(() => {
    formik.setFieldTouched('birthDate', true, true);
  }, []);

  const handleDateChange = (date: Date) => {
    formik.setFieldValue('birthDate', date ? date.toISOString().split('T')[0] : '');
    onFormChange({ birthDate: date }, formik.errors);
  };

  useEffect(() => {
    const birthDate = formik.values.birthDate ? new Date(formik.values.birthDate) : null;
    onFormChange({ birthDate }, formik.errors);
  }, [formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <DatePicker
        name="birthDate"
        label="Date of Birth"
        value={formik.values.birthDate || ''}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Text}
        leftIcon={<GmailIcon />}
        placeholder="Date of Birth"
        error={formik.touched.birthDate && formik.errors.birthDate ? formik.errors.birthDate : ''}
        handleDate={handleDateChange}
        selected={formik.values.birthDate ? new Date(formik.values.birthDate) : new Date()}
        state={InputState.Default}
      />
    </form>
  );
};

export default DateSection;
