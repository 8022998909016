import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks/useAppDispatch';
import { useEffect, useState, useMemo } from 'react';
import Stepper from '../../components/molecules/Stepper/Stepper';
import { FormErrors, FormValues } from '../Profile/types';
import { formatBirthdate } from '../../app/utils/dateFormatter';
import BirthDate from '../Profile/elements/birthdate/Birthdate';
import NickName from '../Profile/elements/nick-name/Nickname';
import Preference from './elements/Preference';
import {
  BD_SUB_TITLE,
  BD_TITLE,
  GD_SUB_TITLE,
  GD_TITLE,
  NN_LABEL1,
  NN_LABEL2,
  NN_PLACEHOLDER1,
  NN_PLACEHOLDER2,
  NN_SUB_TITLE,
  NN_TITLE,
} from './elements/constants';
import Gender from '../Profile/elements/gender/Gender';
import NoseType from './elements/NoseType';
import DateTime from './elements/DateTime';
import { getTimeSlot } from '../../slices/time-slots/thunks/getTimeSlots';
import { ROUTES } from '../../config/routes';
import { createUserTimeSlot } from '../../slices/user-management/thunks';
import { useUserprofile } from '../../slices/auth/selectors';
import { TimeSlotDetails } from '../../slices/user-management/types';
import { getUserTimeSlot } from '../../slices/user-management/thunks/getUserTimeSlots';
import { useTimeSlots } from '../../slices/time-slots/selectors';

interface TimeSlotProps {}

interface Errors {
  birthDate?: string | undefined;
  nickname?: string | undefined;
  initials?: string | undefined;
}

const TimeSlot = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const timeSlotData = useTimeSlots();
  const userProfile = useUserprofile();
  const [stepData, setStepData] = useState<any>({});
  const [setdateTime, setDateTime] = useState({});
  const [errors, setErrors] = useState<Errors>({
    birthDate: undefined,
    nickname: undefined,
    initials: undefined,
  });
  const [isPleasureToMe, setIsPleasureToMe] = useState<boolean>(false);
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (timeSlotData === undefined) {
      dispatch(getTimeSlot());
    }
  }, []);

  const memoizedStepData = useMemo(() => stepData, [stepData]);

  const handleDataChange = (step: number, data: any) => {
    setStepData((prevData: any) => ({
      ...prevData,
      [`step${step}`]: data,
    }));

    if (!isFirstStepCompleted && step === 0 && data === 'More pleasure to me') {
      setIsPleasureToMe(true);
      setIsFirstStepCompleted(true);
    }

    if (isFirstStepCompleted && data === 'More pleasure to partner') {
      setIsPleasureToMe(false);
      setIsFirstStepCompleted(false);
    }
  };

  const handleBirthDate = (data: { birthDate: Date | null }, errors: { birthDate?: string }) => {
    setStepData((prevData: any) => ({
      ...prevData,
      step1: { birthDate: data.birthDate },
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      birthDate: errors.birthDate,
    }));
  };

  const handleNickName = (data: FormValues, errors: FormErrors) => {
    setStepData((prevData: any) => ({
      ...prevData,
      step2: { nickname: data.nickname, initials: data.initials },
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      nickname: errors.nickname,
      initials: errors.initials,
    }));
  };

  const handleDateTime = (date: string, time?: string, tutorials?: string[]) => {
    setStepData((prevData: any) => ({
      ...prevData,
      [isPleasureToMe ? 'step1' : 'step5']: { date, time, tutorials },
    }));
  };

  const onContinue = async (step: number) => {
    switch (step) {
      case 0:
        return !!stepData.step0;
      case 1:
        return !errors.birthDate;
      case 2:
        return !errors.nickname && !errors.initials;
      case 3:
        return true;
      case 4:
        return !!stepData.step4;
      case 5:
        return !!stepData.step5;
      default:
        return false;
    }
  };

  const handleStepperComplete = async () => {
    const commonParams = {
      userId: userProfile?.Username!,
      timeslots: {
        pleasureToMe: isPleasureToMe ? 'true' : 'false',
        birthDate: isPleasureToMe
          ? userProfile?.Birthdate!
          : formatBirthdate(stepData.step1.birthDate),
        nickName: isPleasureToMe ? userProfile?.Nickname! : stepData.step2.nickname,
        realName: isPleasureToMe ? userProfile?.Preferred_username! : stepData.step2.initials,
        gender: isPleasureToMe ? userProfile?.Gender! : stepData.step3,
        timeSlot: {
          date: isPleasureToMe ? stepData.step1.date : stepData.step5.date,
          time: isPleasureToMe ? stepData.step1.time : stepData.step5.time,
          tutorials: isPleasureToMe ? stepData.step1.tutorials : stepData.step5.tutorials,
        },
      },
    };
    if (!isPleasureToMe) {
      (commonParams.timeslots as TimeSlotDetails)['noseType'] = stepData.step4;
    }
    try {
      const actionResult = await dispatch(createUserTimeSlot(commonParams));
      if (createUserTimeSlot.fulfilled.match(actionResult)) {
        await dispatch(getUserTimeSlot());
        navigate(ROUTES.SUCCESS + '?time-slots');
      } else {
        console.error('Time-slot create failed:', actionResult.error.message);
      }
    } catch (error) {
      console.error('Time-slot create failed:', error);
    }
  };

  return (
    <div>
      <Stepper
        steps={[
          <Preference onDataChange={data => handleDataChange(0, data)} />,
          ...(!isPleasureToMe
            ? [
                <BirthDate onSubmit={handleBirthDate} title={BD_TITLE} subTitle={BD_SUB_TITLE} />,
                <NickName
                  onDataChange={handleNickName}
                  title={NN_TITLE}
                  subTitle={NN_SUB_TITLE}
                  label1={NN_LABEL1}
                  label2={NN_LABEL2}
                  placeholder1={NN_PLACEHOLDER1}
                  placeholder2={NN_PLACEHOLDER2}
                />,
                <Gender
                  onDataChange={data => handleDataChange(3, data)}
                  title={GD_TITLE}
                  subTitle={GD_SUB_TITLE}
                />,
                <NoseType onDataChange={data => handleDataChange(4, data)} />,
                <DateTime onDataChange={handleDateTime} />,
              ]
            : [<DateTime onDataChange={handleDateTime} />]),
        ]}
        onContinue={onContinue}
        onComplete={handleStepperComplete}
        stepWidth={isPleasureToMe ? '50%' : '16.6%'}
      />
    </div>
  );
};

export default TimeSlot;
