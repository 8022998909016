import { ALIGNMENT, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT, MARGIN_TOP, TITLE } from '.';
import Title from '../../../atoms/texts/title-text/Title';

interface DividerProps {}

const Divider = () => {
  return (
    <div className="divider">
      <hr className="divider__line" />
      <Title
        text={TITLE}
        alignment={ALIGNMENT}
        fontSize={FONT_SIZE}
        fontWeight={FONT_WEIGHT}
        lineHeight={LINE_HEIGHT}
        marginTop={MARGIN_TOP}
      />
      <hr className="divider__line" />
    </div>
  );
};

export default Divider;
