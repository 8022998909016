import React, { useState } from 'react';
import OtpInput from '../../molecules/TextFields/Otp/OtpInput';

interface OtpContainerProps {
  onComplete: (otp: string) => void;
}

const OtpContainer: React.FC<OtpContainerProps> = ({ onComplete }) => {
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      nextInput?.focus();
    }

    if (newOtp.every(digit => digit !== '')) {
      onComplete(newOtp.join(''));
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      prevInput?.focus();
    }
  };

  return (
    <div className="otp-container">
      {otp.map((digit, index) => (
        <OtpInput
          key={index}
          name={`otp-input-${index}`}
          value={digit}
          onChange={e => handleChange(e, index)}
          onKeyUp={e => handleKeyUp(e, index)}
        />
      ))}
    </div>
  );
};

export default OtpContainer;
