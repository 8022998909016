import { PRODUCT_NAME } from "../../../../config/description";
import { Alignment } from "../../../types/textTypes";

export const ALIGNMENT = Alignment.LEFT 
export const TEXT_TITLE = `Enter Confirmation Code`; 
export const TEXT_DESC = `Please check your email inbox for a message from ${PRODUCT_NAME}. Enter the one-time verification code below.`;
export const MARGIN_TOP_TITLE = "40px";
export const MARGIN_TOP_DESC = "8px";
export const FONT_WEIGHT_TITLE = "700";
export const FONT_WEIGHT_DESC = "400";
export const FONT_SIZE_DESC = "18px";
export const FONT_SIZE_TITLE = "32px";
export const LINE_HEIGHT_TITLE = "51.2px";
export const LINE_HEIGHT_DESC = "28.8px";
export const TEXT_WIDTH_DESC = '382px'