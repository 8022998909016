import { useState } from 'react';
import Title from '../../../atoms/texts/title-text/Title';
import Checkbox from '../../TextFields/Checkbox/Checkbox';
import {
  ALIGNMENT,
  FONT_SIZE,
  FONT_WEIGHT,
  LETTER_SPACING,
  LINE_HEIGHT,
  SMALL_MOBILE_FONT_SIZE,
  SMALL_MOBILE_LINE_HEIGHT,
  TITLE,
} from '.';

interface TermsCheckProps {
  onCheckboxChange: (checked: boolean) => void;
}

const TermsCheck = ({ onCheckboxChange }: TermsCheckProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
    onCheckboxChange(checked);
  };
  return (
    <div
      className="terms-check"
      style={{ display: 'flex', alignItems: 'center', gap: '2.5px', marginTop: '50px' }}
    >
      <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
      <Title
        text={TITLE}
        alignment={ALIGNMENT}
        fontSize={FONT_SIZE}
        fontWeight={FONT_WEIGHT}
        lineHeight={LINE_HEIGHT}
        letterSpacing={LETTER_SPACING}
        smallMobileFontSize={SMALL_MOBILE_FONT_SIZE}
        smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT}
      />
    </div>
  );
};

export default TermsCheck;
