import {
  ALIGNMENT,
  TEXT_TITLE,
  TEXT_DESC,
  MARGIN_TOP_TITLE,
  MARGIN_TOP_DESC,
  FONT_WEIGHT_TITLE,
  FONT_WEIGHT_DESC,
  FONT_SIZE_DESC,
  FONT_SIZE_TITLE,
  LINE_HEIGHT_TITLE,
  LINE_HEIGHT_DESC,
  TEXT_WIDTH_DESC,
  SMALL_MOBILE_MARGIN_BOTTOM,
} from '.';
import TextBlock from '../../../molecules/TextBlock/MainHeadingDescription/TextBlock';
const ResetPasswordDescription = () => {
  return (
    <TextBlock
      alignment={ALIGNMENT}
      textTitle={TEXT_TITLE}
      textDesc={TEXT_DESC}
      marginTopTitle={MARGIN_TOP_TITLE}
      marginTopDesc={MARGIN_TOP_DESC}
      fontWeightTitle={FONT_WEIGHT_TITLE}
      fontWeightDesc={FONT_WEIGHT_DESC}
      fontSizeDesc={FONT_SIZE_DESC}
      fontSizeTitle={FONT_SIZE_TITLE}
      lineHeightTitle={LINE_HEIGHT_TITLE}
      lineHeightDesc={LINE_HEIGHT_DESC}
      widthDesc={TEXT_WIDTH_DESC}
    />
  );
};

export default ResetPasswordDescription;
