import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginPayload, LoginResponse } from '../types';
import axiosInstance from '../../../app/api/axiosInstance';
import { AUTH_URLS, STATE_KEY } from '../constants';

const login = createAsyncThunk<LoginResponse, LoginPayload, { rejectValue: string }>(
    `${STATE_KEY}/login`,
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post<LoginResponse>(AUTH_URLS.LOGIN, { email, password });
        return response.data; 
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to login';
        return rejectWithValue(errorMessage);
      }
    }
);

export { login };
