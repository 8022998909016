import { Container, Row, Col } from 'react-bootstrap';
import ProfileSection from '../../../../components/organisms/description-sections/profile-update-section/ProfileSection';
import { EXPERIENCE_DESCRIPTION, EXPERIENCE_TITLE } from '../constants';
import BeginnerIcon from '../../../../assests/icons/BeginnerIcon';
import CheckIcon from '../../../../assests/icons/CheckIcon';
import ExperienceButton from '../../../../components/molecules/Buttons/ExperienceButton/ExperienceButton';
import IntermediateIcon from '../../../../assests/icons/IntermediateIcon';
import ExpertIcon from '../../../../assests/icons/ExpertIcon';
import { useState } from 'react';

interface ButtonData {
  leftIcon: JSX.Element;
  primaryText: string;
  secondaryText: string;
  checkedIcon: JSX.Element;
  isSelected: boolean;
}

const initialButtonData: ButtonData[] = [
  {
    leftIcon: <BeginnerIcon />,
    primaryText: 'Beginner',
    secondaryText: `I'm new to sex`,
    checkedIcon: <CheckIcon />,
    isSelected: false,
  },
  {
    leftIcon: <IntermediateIcon />,
    primaryText: 'Intermediate',
    secondaryText: `I am having sex regularly`,
    checkedIcon: <CheckIcon />,
    isSelected: false,
  },
  {
    leftIcon: <ExpertIcon />,
    primaryText: 'Expert',
    secondaryText: 'I am experienced & living with sex',
    checkedIcon: <CheckIcon />,
    isSelected: false,
  },
];

interface ExperienceProps {
  onDataChange?: (experience: string) => void;
}

const Experience = ({ onDataChange }: ExperienceProps) => {
  const [buttonData, setButtonData] = useState<ButtonData[]>(initialButtonData);

  const handleButtonClick = (index: number) => {
    setButtonData(prevData =>
      prevData.map((button, i) => {
        if (i === index) {
          return { ...button, isSelected: !button.isSelected };
        }
        return { ...button, isSelected: false };
      }),
    );

    if (onDataChange) {
      onDataChange(buttonData[index].primaryText);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column align-items-center justify-content-center left-tab w-100"
        >
          <div>
            <ProfileSection title={EXPERIENCE_TITLE} description={EXPERIENCE_DESCRIPTION} />
            <div
              style={{ marginTop: '30px' }}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              {buttonData.map((button, index) => (
                <ExperienceButton
                  key={index}
                  leftIcon={button.leftIcon}
                  primaryText={button.primaryText}
                  secondaryText={button.secondaryText}
                  checkedIcon={button.isSelected ? button.checkedIcon : null}
                  isSelected={button.isSelected}
                  onClick={() => handleButtonClick(index)}
                />
              ))}
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column align-items-center justify-content-center w-100"
        ></Col>
      </Row>
    </Container>
  );
};

export default Experience;
