import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, TimeSlotResponse, TimeSlotState } from "./types";
import { STATE_KEY } from "./constants";
import { getTimeSlot } from "./thunks/getTimeSlots";

const timeSlotSlice = createSlice({
    name: STATE_KEY,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getTimeSlot.fulfilled, (state: TimeSlotState, action: PayloadAction<TimeSlotResponse>) => {
            state.timeslots = action.payload.data;
          });
    },
  });

  export default timeSlotSlice.reducer;