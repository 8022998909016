import { Container, Row, Col } from 'react-bootstrap';
import ProfileSection from '../../../../components/organisms/description-sections/profile-update-section/ProfileSection';
import { NICKNAME_DESCRIPTION, NICKNAME_TITLE } from '../constants';
import { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import NickNameSection from '../../../../components/organisms/profile-section/nick-name-section/NickNameSection';
import { FormErrors, FormValues } from '../../types';

interface NickNameProps {
  onDataChange?: (data: FormValues, errors: FormErrors) => void;
  title?: string;
  subTitle?: string;
  label1?: string;
  label2?: string;
  placeholder1?: string;
  placeholder2?: string;
}

const NickName = ({
  onDataChange,
  title,
  subTitle,
  label1,
  label2,
  placeholder1,
  placeholder2,
}: NickNameProps) => {
  const handleNickNameSectionChange = (values: FormValues, errors: FormErrors) => {
    if (onDataChange) {
      onDataChange(values, errors);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center left-tab w-100 flex-column"
        >
          <ProfileSection
            title={title ?? NICKNAME_TITLE}
            description={subTitle ?? NICKNAME_DESCRIPTION}
          />
          <NickNameSection
            onFormChange={handleNickNameSectionChange}
            label1={label1}
            label2={label2}
            placeholder1={placeholder1}
            placeholder2={placeholder2}
          />
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column align-items-center justify-content-center w-100"
        ></Col>
      </Row>
    </Container>
  );
};

export default NickName;
