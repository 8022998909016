import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../molecules/TextFields/InputField/InputField';
import { InputFormType, InputState } from '../../../types/InputField';
import UserIcon from '../../../../assests/icons/UserIcon';
import { FormErrors, FormValues } from '../../../../pages/Profile/types';
import { LABEL1, LABEL2, PLACEHOLDER1, PLACEHOLDER2 } from './contants';

interface NickNameSectionProps {
  onFormChange: (data: FormValues, errors: FormErrors) => void;
  label1?: string;
  label2?: string;
  placeholder1?: string;
  placeholder2?: string;
}

const validationSchema = Yup.object().shape({
  nickname: Yup.string().min(2, 'Nickname too short').required('Required'),
  initials: Yup.string().length(2, 'Must be exactly 2 letters').required('Required'),
});

const NickNameSection = ({
  onFormChange,
  label1,
  label2,
  placeholder1,
  placeholder2,
}: NickNameSectionProps) => {
  const formik = useFormik({
    initialValues: { nickname: '', initials: '' },
    validationSchema,
    onSubmit: values => {},
  });

  useEffect(() => {
    formik.setFieldTouched('nickname', true, true);
    formik.setFieldTouched('initials', true, true);
  }, []);

  useEffect(() => {
    const { nickname, initials } = formik.values;
    onFormChange(
      { nickname: nickname, initials: initials },
      { nickname: formik.errors.nickname, initials: formik.errors.initials },
    );
  }, [formik.values, formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputField
        name="nickname"
        label={label1 ?? LABEL1}
        value={formik.values.nickname}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Text}
        state={
          formik.touched.nickname && formik.errors.nickname ? InputState.Error : InputState.Default
        }
        leftIcon={<UserIcon />}
        placeholder={placeholder1 ?? PLACEHOLDER1}
        error={formik.touched.nickname && formik.errors.nickname ? formik.errors.nickname : ''}
        height="130px"
      />
      <InputField
        name="initials"
        label={label2 ?? LABEL2}
        value={formik.values.initials}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Text}
        state={
          formik.touched.initials && formik.errors.initials ? InputState.Error : InputState.Default
        }
        leftIcon={<UserIcon />}
        placeholder={placeholder2 ?? PLACEHOLDER2}
        error={formik.touched.initials && formik.errors.initials ? formik.errors.initials : ''}
        height="130px"
      />
    </form>
  );
};

export default NickNameSection;
