import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLoginTimeStamp } from '../../slices/auth/selectors';
import { logout } from '../../slices/auth';

const AUTO_LOGOUT_TIME = 3600 * 1000;
// const AUTO_LOGOUT_TIME = 2 * 60 * 1000;

const useAutoLogout = () => {
  const dispatch = useDispatch();
  const loginTimestamp = useLoginTimeStamp();

  useEffect(() => {
    if (!loginTimestamp) return;

    const timeElapsed = Date.now() - loginTimestamp;

    const timeLeft = AUTO_LOGOUT_TIME - timeElapsed;

    if (timeLeft <= 0) {
      // If time has already elapsed, logout immediately
      dispatch(logout());
    } else {
      // Set a timeout to logout after the remaining time
      const timeoutId = setTimeout(() => {
        dispatch(logout());
      }, timeLeft);

      // Clear timeout on component unmount or if loginTimestamp changes
      return () => clearTimeout(timeoutId);
    }
  }, [loginTimestamp, dispatch]);
};

export default useAutoLogout;
