import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Action from '../../organisms/action-section/Action';
import { ButtonText } from '../../types/ButtonTypes';
import { ACTION_CLASS } from '../../organisms/action-section';

interface StepperProps {
  steps: React.ReactNode[];
  onContinue: (step: number) => Promise<boolean>;
  stepWidth?: string;
  onComplete: () => void;
}

const Stepper: React.FC<StepperProps> = ({ steps, onContinue, stepWidth, onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = steps.length;

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = async () => {
    const isValid = await onContinue(currentStep);
    if (isValid) {
      if (currentStep === totalSteps - 1) {
        if (onComplete) onComplete();
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} className="d-flex justify-content-center w-100">
          <div className="stepper">
            {currentStep > 0 && (
              <img
                width="20"
                height="20"
                src="https://img.icons8.com/ios/50/left--v1.png"
                alt="left--v1"
                onClick={prevStep}
                className="back-icon"
              />
            )}

            <div className="stepper-progress">
              {Array.from({ length: totalSteps }, (_, index) => (
                <div
                  key={index}
                  className={`step ${currentStep >= index ? 'completed' : ''}`}
                  style={{ width: stepWidth }}
                ></div>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="d-flex flex-column align-items-center justify-content-center">
        <Col xs={12} className="d-flex flex-column align-items-center">
          <div className="step-content">{steps[currentStep]}</div>
          <Action
            buttonName={ButtonText.Continue}
            onClick={nextStep}
            className={ACTION_CLASS.WEB}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="d-flex justify-content-center w-100 ">
          <Action
            buttonName={ButtonText.Continue}
            onClick={nextStep}
            className={ACTION_CLASS.MOBILE_BOTTOM}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Stepper;
