interface CheckButtonProps {
  primaryText: string;
  checkedIcon: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
}

const CheckButton = ({ primaryText, checkedIcon, isSelected, onClick }: CheckButtonProps) => {
  return (
    <div className="checked-button" onClick={onClick}>
      <div className="button-content">
        <div className="primary-text">{primaryText}</div>
      </div>
      {isSelected && <div className="button-icon-right">{checkedIcon}</div>}
    </div>
  );
};

export default CheckButton;
