interface CheckIconProps {}

const CheckIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7959 7.54597L9.79594 19.546C9.69143 19.6509 9.56723 19.7341 9.43049 19.7909C9.29374 19.8476 9.14713 19.8769 8.99907 19.8769C8.851 19.8769 8.70439 19.8476 8.56765 19.7909C8.4309 19.7341 8.30671 19.6509 8.20219 19.546L2.95219 14.296C2.84755 14.1913 2.76453 14.0671 2.7079 13.9304C2.65127 13.7936 2.62212 13.6471 2.62212 13.4991C2.62212 13.3511 2.65127 13.2046 2.7079 13.0678C2.76453 12.9311 2.84755 12.8069 2.95219 12.7022C3.05684 12.5976 3.18107 12.5146 3.3178 12.4579C3.45453 12.4013 3.60107 12.3721 3.74907 12.3721C3.89706 12.3721 4.04361 12.4013 4.18033 12.4579C4.31706 12.5146 4.4413 12.5976 4.54594 12.7022L9 17.1563L20.2041 5.9541C20.4154 5.74276 20.7021 5.62402 21.0009 5.62402C21.2998 5.62402 21.5865 5.74276 21.7978 5.9541C22.0092 6.16544 22.1279 6.45209 22.1279 6.75098C22.1279 7.04986 22.0092 7.33651 21.7978 7.54785L21.7959 7.54597Z"
        fill="#7E6DFC"
      />
      <path
        d="M21.7959 7.54597L9.79594 19.546C9.69143 19.6509 9.56723 19.7341 9.43049 19.7909C9.29374 19.8476 9.14713 19.8769 8.99907 19.8769C8.851 19.8769 8.70439 19.8476 8.56765 19.7909C8.4309 19.7341 8.30671 19.6509 8.20219 19.546L2.95219 14.296C2.84755 14.1913 2.76453 14.0671 2.7079 13.9304C2.65127 13.7936 2.62212 13.6471 2.62212 13.4991C2.62212 13.3511 2.65127 13.2046 2.7079 13.0678C2.76453 12.9311 2.84755 12.8069 2.95219 12.7022C3.05684 12.5976 3.18107 12.5146 3.3178 12.4579C3.45453 12.4013 3.60107 12.3721 3.74907 12.3721C3.89706 12.3721 4.04361 12.4013 4.18033 12.4579C4.31706 12.5146 4.4413 12.5976 4.54594 12.7022L9 17.1563L20.2041 5.9541C20.4154 5.74276 20.7021 5.62402 21.0009 5.62402C21.2998 5.62402 21.5865 5.74276 21.7978 5.9541C22.0092 6.16544 22.1279 6.45209 22.1279 6.75098C22.1279 7.04986 22.0092 7.33651 21.7978 7.54785L21.7959 7.54597Z"
        fill="black"
      />
    </svg>
  );
};

export default CheckIcon;
