import React, { useEffect, useState, useCallback } from 'react';
import { TimeSlotData } from '../../../slices/time-slots/types';
import DateButton from '../../molecules/Buttons/DateButton/DateButton';
import TimeButton from '../../molecules/Buttons/TimeButton/TimeButton';

interface DateButtonContainerProps {
  timeSlotData: TimeSlotData[];
  onChangeData?: (date: string, time?: string, tutorials?: string[]) => void;
}

const DateButtonContainer = ({ timeSlotData, onChangeData }: DateButtonContainerProps) => {
  const [selected, setSelected] = useState<{
    date?: string;
    time?: string;
    tutorials?: string[];
  }>({});

  useEffect(() => {
    if (selected.date && selected.time && onChangeData) {
      onChangeData(selected.date, selected.time, selected.tutorials);
    }
  }, [selected]);

  const handleDateClick = useCallback((date: string) => {
    setSelected(prev => ({ ...prev, date }));
  }, []);

  const handleTimeClick = useCallback((time: string, tutorials: string[]) => {
    setSelected(prev => ({ ...prev, time, tutorials }));
  }, []);

  useEffect(() => {
    if (timeSlotData.length > 0) {
      const initialData = timeSlotData[0].timeSlots[0];
      setSelected({
        date: timeSlotData[0].date,
        time: initialData.time,
        tutorials: initialData.tutorials,
      });
    }
  }, [timeSlotData]);

  const selectedTimeSlots = timeSlotData.find(slot => slot.date === selected.date)?.timeSlots || [];

  return (
    <div className="date-button-container">
      <span>Select your ideal date</span>
      <div className="button-list">
        {timeSlotData.map((timeSlot, index) => {
          const dayOnly = new Date(timeSlot.date).getDate();
          const isSelected = timeSlot.date === selected.date;

          return (
            <div
              key={index}
              onClick={() => handleDateClick(timeSlot.date)}
              className={`date-button-item ${isSelected ? 'selected' : ''}`}
            >
              <DateButton day={timeSlot.day} date={String(dayOnly)} isSelected={isSelected} />
            </div>
          );
        })}
      </div>

      {selected.date && (
        <div className="time-selection-container">
          <span>Select your ideal time</span>
          <div className="button-list-time">
            {selectedTimeSlots.map((timeSlot, index) => {
              const isSelected = timeSlot.time === selected.time;
              return (
                <div
                  key={index}
                  onClick={() => handleTimeClick(timeSlot.time, timeSlot.tutorials)}
                  className={`time-button-item ${isSelected ? 'selected' : ''}`}
                >
                  <TimeButton time={timeSlot.time} type={timeSlot.feel} isSelected={isSelected} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateButtonContainer;
