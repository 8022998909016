import { createAsyncThunk } from "@reduxjs/toolkit";
import { ForgotPasswordRequest, ForgotPasswordResponse } from "../types";
import axiosInstance from "../../../app/api/axiosInstance";
import { AUTH_URLS, STATE_KEY } from "../constants";

export const forgotPassword = createAsyncThunk<ForgotPasswordResponse, ForgotPasswordRequest>(
    `${STATE_KEY}/forgot-password`,
    async (requestData, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post<ForgotPasswordResponse>(AUTH_URLS.FORGOT_PASSWORD, requestData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Password reset initiation failed');
        }
    }
);