import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginPayload, LoginResponse, RegisterPayload, RegisterResponse } from '../types';
import axiosInstance from '../../../app/api/axiosInstance';
import { AUTH_URLS, STATE_KEY } from '../constants';

const register = createAsyncThunk<RegisterResponse, RegisterPayload, { rejectValue: string }>(
  `${STATE_KEY}/register`,
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post<RegisterResponse>(AUTH_URLS.REGISTER, { email, password });
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to register';
      return rejectWithValue(errorMessage);
    }
  }
);

export { register }