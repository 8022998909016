import { CARD_CONTENTS } from '../../../config/cards';
import Card from '../../atoms/cards/Card';
import Title from '../../atoms/texts/title-text/Title';
import {
  SMALL_MOBILE_TITLE_WIDTH,
  TITLE,
  TITLE_ALIGNMENT,
  TITLE_FONT_SIZE,
  TITLE_FONT_WEIGHT,
  TITLE_LINE_HEIGHT,
  TITLE_MARGIN_TOP,
  TITLE_WIDTH,
} from './constants';

interface CardSectionProps {}

const CardSection = () => {
  const isSmallMobileScreen = window.innerWidth <= 401;
  const appliedWidth =
    isSmallMobileScreen && isSmallMobileScreen ? SMALL_MOBILE_TITLE_WIDTH : TITLE_WIDTH;
  return (
    <div className="mb-5 card-section">
      <Title
        text={TITLE}
        alignment={TITLE_ALIGNMENT}
        marginTop={TITLE_MARGIN_TOP}
        fontSize={TITLE_FONT_SIZE}
        fontWeight={TITLE_FONT_WEIGHT}
        lineHeight={TITLE_LINE_HEIGHT}
        width={appliedWidth}
      />
      {CARD_CONTENTS.map((card, index) => (
        <div key={index}>
          <Card
            backgroundImage={card.image}
            title={card.title}
            description={card.description}
            buttonText="Learn More"
            onButtonClick={() => console.log(`${card.title} clicked`)}
          />
        </div>
      ))}
    </div>
  );
};

export default CardSection;
