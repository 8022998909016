export const BIRTHDAY_TITLE = 'Add Your Birth Details';
export const BIRTHDAY_DESCRIPTION = `Let's start by understanding you.`;

export const NICKNAME_TITLE = 'Add Your Nickname';
export const NICKNAME_DESCRIPTION = `Let's start by understanding you.`;


export const GENDER_TITLE = 'Select Your Gender';
export const GENDER_DESCRIPTION = `Let's start by understanding you.`;

export const EXPERIENCE_TITLE = 'Have You Experienced Sex before?';
export const EXPERIENCE_DESCRIPTION = 'Share your sex background with us.';