import TextBlock from '../../../molecules/TextBlock/MainHeadingDescription/TextBlock';
import { Alignment } from '../../../types/textTypes';

interface WelcomeDescriptionProps {}

const WelcomeDescription = () => {
  return (
    <TextBlock
      alignment={Alignment.CENTER}
      textTitle={`Let's Get Started!`}
      textDesc={`Let's dive in into your account`}
      marginTopTitle="40px"
      marginTopDesc="12px"
      fontWeightTitle="700"
      fontWeightDesc="400"
      fontSizeDesc="18px"
      fontSizeTitle="32px"
      lineHeightTitle="51.2px"
      lineHeightDesc="28.8px"
      smallMarginBottom="40px"
    />
  );
};

export default WelcomeDescription;
