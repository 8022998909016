import { useAppDispatch } from '../../../../app/hooks/useAppDispatch';
import { socialMediaButtons as buttonConfig } from '../../../../config/buttons/config-buttons';
import { googleLogin } from '../../../../slices/auth/thunk/googleLogin';
import PrimaryButton from '../../../molecules/Buttons/PrimaryButton/PrimaryButton';
import { useGoogleLogin } from '@react-oauth/google';
import { SocialMediaPlatform } from '../../../types/ButtonTypes';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../config/routes';
import { useState } from 'react';
import LoaderModal from '../../../molecules/Loader/LoaderModal';
import { LoaderTitleTypes } from '../../../types/loaderTitleTypes';
import { hideLoader, showLoader } from '../../../../slices/loader';
import { getUser } from '../../../../slices/auth/thunk/get-user';

interface SocialLoginProps {
  marginTop?: string;
}

const SocialLogin = ({ marginTop }: SocialLoginProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        dispatch(showLoader(LoaderTitleTypes.SIGNIN));

        const loginResult = await dispatch(googleLogin(code));
        if (googleLogin.fulfilled.match(loginResult)) {
          const userResult = await dispatch(getUser());
          if (getUser.fulfilled.match(userResult)) {
            navigate(ROUTES.DASHBOARD);
            dispatch(hideLoader());
          } else {
            console.error('Failed to fetch user data:', userResult);
            dispatch(hideLoader());
          }
        } else {
          console.error('Google login failed:', loginResult);
          dispatch(hideLoader());
        }
      } catch (error) {
        console.error('An error occurred during Google login:', error);
        dispatch(hideLoader());
      }
    },
    flow: 'auth-code',
  });

  const handleFacebookLogin = () => {
    // Assume this gets the Facebook auth token
    const facebookToken = 'sampleFacebookToken';
    // dispatch(facebookLogin(facebookToken));
  };

  const handleAppleLogin = () => {
    // Assume this gets the Apple auth token
    const appleToken = 'sampleAppleToken';
    // dispatch(appleLogin(appleToken));
  };

  const socialMediaButtons = buttonConfig.map(button => ({
    ...button,
    onClick: (() => {
      switch (button.socialMediaPlatform) {
        case SocialMediaPlatform.Google:
          return handleGoogleLogin;
        case SocialMediaPlatform.Facebook:
          return handleFacebookLogin;
        case SocialMediaPlatform.Apple:
          return handleAppleLogin;
        default:
          return button.onClick;
      }
    })(),
  }));

  return (
    <>
      <div className="social-media-section" style={{ marginTop: marginTop }}>
        {socialMediaButtons.map((button, index) => (
          <PrimaryButton
            key={index}
            socialMediaPlatform={button.socialMediaPlatform}
            text={button.text}
            onClick={button.onClick}
            marginBottom={button.marginBottom}
          />
        ))}
      </div>
    </>
  );
};

export default SocialLogin;
