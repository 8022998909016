interface GmailIconProps {}

const GmailIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81346 11.7212C9.25596 11.7212 8.70012 11.5371 8.23512 11.1687L4.49762 8.1554C4.22846 7.93873 4.18679 7.54456 4.40262 7.27623C4.62012 7.00873 5.01346 6.96623 5.28179 7.18206L9.01596 10.1921C9.48512 10.5637 10.146 10.5637 10.6185 10.1887L14.3151 7.18373C14.5835 6.96456 14.9768 7.00623 15.1951 7.27456C15.4126 7.54206 15.3718 7.9354 15.1043 8.15373L11.401 11.1637C10.9326 11.5354 10.3726 11.7212 9.81346 11.7212Z"
        fill="#212121"
      />
      <mask
        id="mask0_200_3567"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="19"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.833008 2.16699H18.7496V18.417H0.833008V2.16699Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_200_3567)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.69884 17.167H13.8822C13.8838 17.1653 13.8905 17.167 13.8955 17.167C14.8463 17.167 15.6897 16.827 16.3363 16.1812C17.0872 15.4337 17.4997 14.3595 17.4997 13.157V7.43366C17.4997 5.10616 15.978 3.41699 13.8822 3.41699H5.70051C3.60467 3.41699 2.08301 5.10616 2.08301 7.43366V13.157C2.08301 14.3595 2.49634 15.4337 3.24634 16.1812C3.89301 16.827 4.73717 17.167 5.68717 17.167H5.69884ZM5.68467 18.417C4.39884 18.417 3.25051 17.9503 2.36384 17.067C1.37634 16.082 0.833008 14.6937 0.833008 13.157V7.43366C0.833008 4.43116 2.92551 2.16699 5.70051 2.16699H13.8822C16.6572 2.16699 18.7497 4.43116 18.7497 7.43366V13.157C18.7497 14.6937 18.2063 16.082 17.2188 17.067C16.333 17.9495 15.1838 18.417 13.8955 18.417H13.8822H5.70051H5.68467Z"
          fill="#212121"
        />
      </g>
    </svg>
  );
};

export default GmailIcon;
