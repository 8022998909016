
interface TimeSlot {
    feel?: string;         
    time: string;            
    tutorials: string[];
  }
  
export  interface TimeSlotData {
    date: string;              
    userId: string;          
    day: string;            
    timeSlots: TimeSlot[]; 
}

export interface TimeSlotResponse {
    message : string,
    data : TimeSlotData[]
}

export interface TimeSlotState {
    timeslots: TimeSlotData[] | undefined;
}

export const initialState: TimeSlotState = {
    timeslots: undefined,
}