// InputField.tsx
import React, { useState } from 'react';
import { InputFieldProps, InputState } from '../../../types/InputField';
import { FormikErrors } from 'formik/dist/types';

interface FormikInputFieldProps extends InputFieldProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  height?: string;
}

const InputField: React.FC<FormikInputFieldProps> = ({
  name,
  value,
  onChange,
  onBlur,
  type,
  state = InputState.Default,
  label,
  darkMode = false,
  showLeftIcon = true,
  showRightIcon = false,
  placeholder,
  error,
  leftIcon,
  rightIcon,
  height,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="input-field" style={{ height: height }}>
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}

      <div className="input-wrapper">
        {showLeftIcon && leftIcon}

        <input
          id={name}
          name={name}
          type={isPasswordVisible && type === 'password' ? 'text' : type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className="input"
        />

        {showRightIcon && (
          <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
            {rightIcon}
          </span>
        )}
      </div>

      {error && <div className="input-error">{error as string}</div>}
    </div>
  );
};

export default InputField;
