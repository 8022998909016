import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavigationState {
  routes: string[];
}

const initialState: NavigationState = {
  routes: [],
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
      updateRoutes: (state, action: PayloadAction<string>) => {
        const currentPath = action.payload;
        state.routes = [...state.routes.slice(-1), currentPath];
      },
    },
  });
  
  export const { updateRoutes } = navigationSlice.actions;
export const selectRoutes = (state: { navigation: NavigationState }) => state.navigation.routes;

export default navigationSlice.reducer;