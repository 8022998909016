import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResetPasswordRequest, ResetPasswordResponse } from "../types";
import { AUTH_URLS, STATE_KEY } from "../constants";
import axiosInstance from "../../../app/api/axiosInstance";

export const resetPassword = createAsyncThunk<ResetPasswordResponse, ResetPasswordRequest>(
    `${STATE_KEY}/resetPassword`,
    async (requestData, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post<ResetPasswordResponse>(AUTH_URLS.RESET_PASSWORD, requestData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Password reset failed');
        }
    }
);