import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from '@reduxjs/toolkit'; // Import combineReducers
import userReducer from '../slices/user-management/index';
import navigationReducer from '../slices/navigation/navigationSlice';
import authReducer from '../slices/auth/index';
import loaderReducer from '../slices/loader/index';
import timeSlotReducer from '../slices/time-slots/index';

// Define a persist config
const persistConfig = {
    key: 'root',
    storage, 
};

// Combine the reducers into a single root reducer
const rootReducer = combineReducers({
    user: userReducer,
    navigation: navigationReducer,
    auth: authReducer,
    loader: loaderReducer,
    timeSlot: timeSlotReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer, 
});

// Create a persistor
export const persistor = persistStore(store); 

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
