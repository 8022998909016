export enum ButtonType {
    Pill = 'Pill',
    Square = 'Square',
  }
  
  export enum ButtonSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
  }
  
  export enum ButtonTheme {
    Light = 'Light',
    Dark = 'Dark',
    Rose = 'Rose',
  }
  
  export enum SocialMediaPlatform {
    Google = 'Google',
    Facebook = 'Facebook',
    Apple = 'Apple',
  }
  
  export enum ButtonText {
    Google = 'Continue with Google',
    Facebook = 'Continue with Facebook',
    Apple = 'Continue with Apple',
    SignUp = 'Sign up',
    Login = 'Log in',
    Continue = 'Continue',
    GoHome = 'Go to Homepage',
    ForgotPassword = 'Send Verification Code',
    AccountVerify = 'Verify Account',
    ResetPassword = 'Reset Password',
    ConfirmCode = 'Confirm Code',
    CreateTimeSlot = 'Create a Timeslot',
  }

  export enum MiddleIcons {
    CLOCK = 'CLOCK',
  }