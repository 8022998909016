import { Alignment } from "../../../types/textTypes";


export const ALIGNMENT = Alignment.CENTER 
export const TEXT_DESC = `Please Wait...`;
export const MARGIN_TOP_TITLE = "30px";
export const MARGIN_TOP_DESC = "8px";
export const FONT_WEIGHT_TITLE = "700";
export const FONT_WEIGHT_DESC = "400";
export const FONT_SIZE_DESC = "18px";
export const FONT_SIZE_TITLE = "32px";
export const LINE_HEIGHT_TITLE = "51.2px";
export const LINE_HEIGHT_DESC = "28.8px";
export const WIDTH_TITLE = '382px'
export const CONSIDER_LINE_HEIGHT_MOBILE = true;
export const SMALL_MOBILE_WIDTH_DESC = 'unset'
