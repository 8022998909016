import Roman from "../../../assests/images/roman.png";
import Nubian from "../../../assests/images/nubian.png";
import Straight from "../../../assests/images/straight.png";
import Crooked from "../../../assests/images/crooked.png";
import Fleshy from "../../../assests/images/fleshy.png";
import Button from "../../../assests/images/buttonNose.png";
import Hawk from "../../../assests/images/hawk.png";
import Snub from "../../../assests/images/snub.png";
import Big from "../../../assests/images/big.png";

export const TITLE = 'Select Your Preference';
export const SUB_TITLE = 'Tell us about your daily routine.'

export const BD_TITLE = `Partner’s Birth Details`;
export const BD_SUB_TITLE = `Let's start by understanding your partner.`

export const NN_TITLE = `Partner’s Nickname`;
export const NN_SUB_TITLE = `Let's start by understanding your partner.`

export const NN_LABEL1 = `What’s your partner’s nickname`;
export const NN_LABEL2 = `First two letters of your partner’s real name`

export const NN_PLACEHOLDER1 = `Your partner’s nickname`;
export const NN_PLACEHOLDER2 = `Your partner’s real name.`

export const GD_TITLE = 'Select Partner’s Gender';
export const GD_SUB_TITLE = `Let's start by understanding your partner.`

export const NT_TITLE = 'Partner’s Nose Type?';
export const NT_SUB_TITLE = 'We can identify your partner’s personality'

export const DT_TITLE = 'Preferred Date and Time';
export const DT_SUB_TITLE = 'Let us unveil the perfect time slots for an unforgettable experience tailored just for you.'

export interface ButtonData {
    primaryText: string;
    checkedIcon: JSX.Element;
    isSelected: boolean;
  }
  

export const NoseButtonList =[
  {
    "src": Roman,
    "title": "Roman",
  },
  {
    "src": Straight,
    "title": "Nubian",
  },
  {
    "src": Roman,
    "title": "Straight",
  },
  {
    "src": Crooked,
    "title": "Crooked",
  },
  {
    "src": Fleshy,
    "title": "Fleshy",
  },
  {
    "src": Button,
    "title": "Button",
  },
  {
    "src": Hawk,
    "title": "Hawk",
  },
  {
    "src": Snub,
    "title": "Snub",
  },
  {
    "src": Big,
    "title": "Big",
  }
]