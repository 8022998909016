import { useFormik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import * as Yup from 'yup';
import GmailIcon from '../../../assests/icons/GmailIcon';
import { InputFormType, InputState } from '../../types/InputField';
import InputField from '../../molecules/TextFields/InputField/InputField';
import { LABEL, NAME, PLACEHOLDER } from '.';

interface ForgotPasswordSectionProps {
  onFormChange: (values: { email: string }) => void;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const ForgotPasswordSection = forwardRef(({ onFormChange }: ForgotPasswordSectionProps, ref) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values: any) => {
      console.log(values);
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await formik.submitForm();
    },
    getErrors: () => formik.errors,
  }));

  useEffect(() => {
    onFormChange(formik.values);
  }, [formik.values, onFormChange]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputField
        name={NAME}
        label={LABEL}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Text}
        state={formik.touched.email && formik.errors.email ? InputState.Error : InputState.Default}
        leftIcon={<GmailIcon />}
        placeholder={PLACEHOLDER}
        error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
      />
    </form>
  );
});

export default ForgotPasswordSection;
