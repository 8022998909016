import { Container, Row, Col } from 'react-bootstrap';
import BackButton from '../../../components/molecules/Buttons/BackButton/BackButton';
import Action from '../../../components/organisms/action-section/Action';
import { ButtonText } from '../../../components/types/ButtonTypes';
import { useEffect, useRef, useState } from 'react';
import { ACTION_CLASS } from '../../../components/organisms/action-section';
import ResetPasswordDescription from '../../../components/organisms/description-sections/reset-password-description/ResetPasswordDescription';
import ResetPasswordSection from '../../../components/organisms/reset-password-section/ResetPasswordSection';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { useAppDispatch } from '../../../app/hooks/useAppDispatch';
import { hideLoader, showLoader } from '../../../slices/loader';
import { LoaderTitleTypes } from '../../../components/types/loaderTitleTypes';
import { resetPassword } from '../../../slices/auth/thunk/reset-password';
interface ResetPasswordProps {}

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [formValues, setFormValues] = useState({ newPassword: '', confirmPassword: '' });
  const [email, setEmail] = useState<string>('');
  const [otp, setOtp] = useState<string>('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setEmail(queryParams.get('email') || '');
    setOtp(queryParams.get('otp') || '');
  }, [location]);

  const resetPasswordSectionRef = useRef<{
    submitForm: () => Promise<void>;
    getErrors: () => Record<string, string>;
  }>(null);

  const handleResetPasswordChange = (values: { newPassword: string; confirmPassword: string }) => {
    setFormValues(values);
  };

  const handleResetPassword = async () => {
    if (resetPasswordSectionRef.current) {
      await resetPasswordSectionRef.current.submitForm();
    }

    const errors = resetPasswordSectionRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      return;
    }

    dispatch(showLoader(LoaderTitleTypes.PASSWORD_RESET));
    const resetPasswordRequest = {
      email,
      verificationCode: otp,
      newPassword: formValues.newPassword,
    };

    const actionResult = await dispatch(resetPassword(resetPasswordRequest));

    try {
      if (resetPassword.fulfilled.match(actionResult)) {
        navigate(ROUTES.RESET_PASSWORD_SUCCESSFULLY);
      } else {
        console.error('Login failed:', actionResult.error.message);
      }
    } finally {
      dispatch(hideLoader());
    }
  };
  return (
    <div>
      <BackButton />
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Row className="text-center w-100">
          <Col
            xs={12}
            md={6}
            className="d-flex align-items-center justify-content-center left-tab w-100"
          >
            <div>
              <ResetPasswordDescription />
              <ResetPasswordSection
                ref={resetPasswordSectionRef}
                onFormChange={handleResetPasswordChange}
              />
              <Action
                buttonName={ButtonText.ResetPassword}
                onClick={handleResetPassword}
                className={ACTION_CLASS.WEB}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-center justify-content-center w-100"
          >
            <Action
              buttonName={ButtonText.ResetPassword}
              onClick={handleResetPassword}
              className={ACTION_CLASS.MOBILE_BOTTOM}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
