import useNavigateTo from '../../../../app/hooks/useNavigateTo';
import PrimaryButton, {
  PrimaryButtonProps,
} from '../../../molecules/Buttons/PrimaryButton/PrimaryButton';

interface ManualLoginSignUpProps {
  authButtons: PrimaryButtonProps[];
}

const ManualLoginSignUp = ({ authButtons }: ManualLoginSignUpProps) => {
  const navigateTo = useNavigateTo();

  const handleButtonClick = (url: string) => {
    navigateTo(url);
  };

  return (
    <div className="auth-buttons">
      {authButtons.map((button, index) => (
        <PrimaryButton
          key={index}
          text={button.text}
          theme={button.theme}
          showIcon={button.showIcon}
          onClick={() => button.url && handleButtonClick(button.url)}
          marginBottom={button.marginBottom}
        />
      ))}
    </div>
  );
};

export default ManualLoginSignUp;
