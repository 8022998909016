import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../app/api/axiosInstance";
import { TimeSlotResponse } from "../types";
import { STATE_KEY, TIMESLOT_URLS } from "../constants";
import { RootState } from "../../../app/store";

export const getTimeSlot = createAsyncThunk<TimeSlotResponse, void, { state: RootState }>(
  `${STATE_KEY}/getTimeSlot`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.accessToken;

      const response = await axiosInstance.get<TimeSlotResponse>(TIMESLOT_URLS.GET_TIMESLOTS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      console.error("Error fetching timeslot:", error);
      return rejectWithValue(error.response?.data || "Failed to fetch timeslot");
    }
  }
);
