import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputFormType, InputState } from '../../types/InputField';
import InputField from '../../molecules/TextFields/InputField/InputField';
import GmailIcon from '../../../assests/icons/GmailIcon';
import PasswordIcon from '../../../assests/icons/PasswordIcon';
import CloseEyeIcon from '../../../assests/icons/CloseEyeIcon';

interface AuthSectionProps {
  onFormChange: (values: { email: string; password: string }, isValid: boolean) => void;
}

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must include at least one special character')
    .required('Required'),
});

// Forward ref to access Formik’s submitForm
const AuthSection = forwardRef(({ onFormChange }: AuthSectionProps, ref) => {
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: values => {},
  });

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await formik.submitForm();
    },
    getErrors: () => formik.errors,
  }));

  useEffect(() => {
    onFormChange(formik.values, formik.isValid);
  }, [formik.values, onFormChange]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputField
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Text}
        state={formik.touched.email && formik.errors.email ? InputState.Error : InputState.Default}
        leftIcon={<GmailIcon />}
        placeholder="Enter your email"
        error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
      />
      <InputField
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type={InputFormType.Password}
        state={
          formik.touched.password && formik.errors.password ? InputState.Error : InputState.Default
        }
        leftIcon={<PasswordIcon />}
        rightIcon={<CloseEyeIcon />}
        placeholder="Enter your password"
        showRightIcon={true}
        error={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
      />
    </form>
  );
});

export default AuthSection;
