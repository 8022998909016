import { text } from 'stream/consumers';
import { SocialMediaPlatform, ButtonText, ButtonTheme } from '../../components/types/ButtonTypes';
import { PrimaryButtonProps } from '../../components/molecules/Buttons/PrimaryButton/PrimaryButton';

export const socialMediaButtons : PrimaryButtonProps[] = [
  { socialMediaPlatform: SocialMediaPlatform.Google, text: ButtonText.Google, onClick: () => console.log("Google button clicked!"), marginBottom:'20px' },
  { socialMediaPlatform: SocialMediaPlatform.Apple, text: ButtonText.Apple, onClick: () => console.log("Apple button clicked!"), marginBottom:'20px' },
  { socialMediaPlatform: SocialMediaPlatform.Facebook, text: ButtonText.Facebook, onClick: () => console.log("Facebook button clicked!"), marginBottom:'20px' },
];


export const authButtons : PrimaryButtonProps[] = [
  {text: ButtonText.SignUp, theme: ButtonTheme.Dark, showIcon: false,  url:'/signup' , marginBottom:'20px'  },
  {text: ButtonText.Login, theme: ButtonTheme.Rose, showIcon: false,  url:'/login' }
];