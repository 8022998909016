import { createAsyncThunk } from "@reduxjs/toolkit";
import { ForgotPasswordRequest, ForgotPasswordResponse, UpdateProfileRequest, UpdateProfileResponse } from "../types";
import axiosInstance from "../../../app/api/axiosInstance";
import { AUTH_URLS, STATE_KEY } from "../constants";
import { RootState } from "../../../app/store";

export const updateProfile = createAsyncThunk<UpdateProfileResponse,  UpdateProfileRequest>(
    `${STATE_KEY}/update-profile`,
    async (requestData, { rejectWithValue, getState }) => {
        try {
            const token = (getState() as RootState).auth.accessToken;

            const response = await axiosInstance.put<UpdateProfileResponse>(
                AUTH_URLS.PROFILE_UPDATE,
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Password reset initiation failed');
        }
    }
);