// Payload for login request
export interface LoginPayload {
    email: string;
    password: string;
  }
  
  export interface RegisterPayload {
    email: string;
    password: string;
  }

  // Structure of the user data
  export interface User {
    id: number;
    name?: string;
    email: string;
  }
  
  export interface UserAttribute {
    Name: string;
    Value: string;
}


export interface RawUserProfileResponse {
  Username: string;
  Attributes: UserAttribute[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Enabled: boolean;
  UserStatus: string;
}

export interface UserProfileResponse {
    Username: string;
    Email: string;
    EmailVerified: boolean;
    Nickname: string;
    Name: string;
    FamilyName: string;
    Preferred_username: string;
    GivenName: string;
    Picture: string;
    Gender: string;
    Birthdate: string;
    UserStatus: string;
    Experience: string;
    ProfileUpdate: string;
    Sub: string;
    UserCreateDate: string;
    UserLastModifiedDate: string;
    Enabled: boolean;
    UserStatusCode: string;
}


export interface UserProfile {
  Username: string;
  Email: string;
  EmailVerified: boolean;
  Nickname: string;
  Preferred_username: string;
  Name: string;
  FamilyName: string;
  GivenName: string;
  Picture: string;
  Gender: string;
  Birthdate: string;
  UserStatus: string;
  Experience: string;
  ProfileUpdate: string;
  Sub: string;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Enabled: boolean;
  UserStatusCode: string;
}


  // Structure of a token payload
  interface TokenPayload {
    jwtToken: string;
    payload: {
      sub: string;
      email?: string;
      email_verified?: boolean;
      [key: string]: any;
    };
  }
  
  // Login response structure
  export interface LoginResponse {
    message: string;
    data: {
      idToken: TokenPayload;
      refreshToken: { token: string };
      accessToken: TokenPayload;
    };
  }
  
  export interface CodeDeliveryDetails {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
  }
  
  export interface UserPool {
    userPoolId: string;
    clientId: string;
    client: {
      endpoint: string;
      fetchOptions: Record<string, unknown>;
    };
    advancedSecurityDataCollectionFlag: boolean;
  }
  
  export interface CognitoUser {
    username: string;
    pool: UserPool;
    Session: string | null;
    client: {
      endpoint: string;
      fetchOptions: Record<string, unknown>;
    };
    signInUserSession: string | null;
    authenticationFlowType: string;
    keyPrefix: string;
    userDataKey: string;
  }
  
  export interface RegisterResponse {
    message: string;
    data: {
      user: CognitoUser;
      userConfirmed: boolean;
      userSub: string;
      codeDeliveryDetails: CodeDeliveryDetails;
    };
  }

export interface RegisteredUser{
  user: CognitoUser,
  userConfirmed: boolean;
}

export interface VerifyRequest {
  email: string;
  verificationCode: string;
}

export interface VerifyResponse {
  message: string;
  data: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface ForgotPasswordResponse {
  message: string;
  data: {
      CodeDeliveryDetails: CodeDeliveryDetails;
  };
}


export interface ResetPasswordRequest {
  email: string;
  verificationCode: string;
  newPassword: string;
}

export interface ResetPasswordResponse {
  message: string;
  data: string;
}

export interface UpdateProfileRequest {
  birthdate: string;          
  nickname: string; 
  gender: string;
  preferred_username: string;
  "custom:experience": string;
  "custom:profile-update": string,
}

export interface UpdateProfileResponse {
  message: string;          
}

  // Authentication state
  export interface AuthState {
    user: User | undefined;
    idToken: string | undefined;
    refreshToken: string | undefined;
    accessToken: string | undefined;
    registeredUser: RegisteredUser | undefined;
    loginTimestamp: number | undefined;
    userOnboarded: boolean | undefined;
    userProfile: UserProfile | undefined;
  }

  // Initial state for the authentication slice
  export const initialState: AuthState = {
    user: undefined,
    idToken: undefined,
    refreshToken: undefined,
    accessToken: undefined,
    registeredUser: undefined,
    loginTimestamp: undefined,
    userOnboarded: undefined,
    userProfile: undefined,
  };
  