import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { UserTimeSlotResponse } from "../types";
import axiosInstance from "../../../app/api/axiosInstance";
import { STATE_KEY, USER__URLS } from "../constants";

export const getUserTimeSlot = createAsyncThunk<UserTimeSlotResponse, void, { state: RootState }>(
  `${STATE_KEY}/getUserTimeSlot`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.accessToken;
      const userId = state.auth.userProfile?.Username;

      const response = await axiosInstance.get<UserTimeSlotResponse>(
        `${USER__URLS.GET_TIMESLOTS}?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.error("Error fetching time-slot:", error);
      return rejectWithValue(error.response?.data || "Failed to fetch time-slot");
    }
  }
);
