import { Container, Row, Col } from 'react-bootstrap';
import BackButton from '../../../components/molecules/Buttons/BackButton/BackButton';
import Action from '../../../components/organisms/action-section/Action';
import { ButtonText } from '../../../components/types/ButtonTypes';
import ForgotPasswordDescription from '../../../components/organisms/description-sections/forgot-password-description/ForgotPasswordDescription';
import ForgotPasswordSection from '../../../components/organisms/forgot-password-section/ForgotPasswordSection';
import { useRef, useState } from 'react';
import { ACTION_CLASS } from '../../../components/organisms/action-section';
import { ROUTES } from '../../../config/routes';
import { useAppDispatch } from '../../../app/hooks/useAppDispatch';
import { hideLoader, showLoader } from '../../../slices/loader';
import { LoaderTitleTypes } from '../../../components/types/loaderTitleTypes';
import { forgotPassword } from '../../../slices/auth/thunk/forgot-password';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ email: '' });
  const authSectionRef = useRef<{
    submitForm: () => Promise<void>;
    getErrors: () => Record<string, string>;
  }>(null);

  const handleAuthSectionChange = (values: { email: string }) => {
    setFormValues(values);
  };

  const handleForgotPassword = async () => {
    if (authSectionRef.current) {
      await authSectionRef.current.submitForm();
    }

    const errors = authSectionRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      return;
    }

    dispatch(showLoader(LoaderTitleTypes.SEND_CODE));
    const actionResult = await dispatch(forgotPassword(formValues));

    try {
      if (forgotPassword.fulfilled.match(actionResult)) {
        const emailEncoded = encodeURIComponent(formValues.email);
        navigate(`${ROUTES.ACCOUNT_VERIFY}?email=${emailEncoded}`);
      } else {
        console.error('Login failed:', actionResult.error.message);
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div>
      <BackButton />
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Row className="text-center w-100">
          <Col
            xs={12}
            md={6}
            className="d-flex align-items-center justify-content-center left-tab w-100"
          >
            <div>
              <ForgotPasswordDescription />
              <ForgotPasswordSection ref={authSectionRef} onFormChange={handleAuthSectionChange} />
              <Action
                buttonName={ButtonText.ForgotPassword}
                onClick={handleForgotPassword}
                className={ACTION_CLASS.WEB}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-center justify-content-center w-100"
          >
            <Action
              buttonName={ButtonText.ForgotPassword}
              onClick={handleForgotPassword}
              className={ACTION_CLASS.MOBILE_BOTTOM}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
