import { PRODUCT_NAME } from '../../../../config/description';
import TextBlock from '../../../molecules/TextBlock/MainHeadingDescription/TextBlock';
import { Alignment } from '../../../types/textTypes';

interface SignUpDescriptionProps {}

const SignUpDescription = () => {
  return (
    <TextBlock
      alignment={Alignment.LEFT}
      textTitle={`Join ${PRODUCT_NAME} Today`}
      textDesc={`Start your personalized sex experience.`}
      marginTopDesc="8px"
      fontWeightTitle="700"
      fontWeightDesc="400"
      fontSizeDesc="18px"
      fontSizeTitle="32px"
      lineHeightTitle="51.2px"
      lineHeightDesc="28.8px"
      marginTopTitle="40px"
    />
  );
};

export default SignUpDescription;
