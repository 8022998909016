import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../../components/atoms/logo/logo';
import TextBlock from '../../../components/molecules/TextBlock/MainHeadingDescription/TextBlock';
import { authButtons } from '../../../config/buttons/config-buttons';
import PrivacyTerms from '../../../components/molecules/PrivacyTerms/PrivacyTerms';
import ManualLoginSignUp from '../../../components/organisms/login-sections/ManualLoginSection/ManualLoginSignup';
import SocialLogin from '../../../components/organisms/login-sections/FederatedLoginSection/SocialLogin';
import WelcomeDescription from '../../../components/organisms/description-sections/welcome-description/WelcomeDescription';

interface WelcomeProps {}

const Welcome = () => {
  return (
    <Container fluid className="d-flex vh-100 align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
          <div>
            <Logo width="75" height="75" />
            <WelcomeDescription />
          </div>
        </Col>
        <Col xs={12} md={6} className="d-flex flex-column align-items-center">
          <SocialLogin />
          <ManualLoginSignUp authButtons={authButtons} />
          <PrivacyTerms />
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;
