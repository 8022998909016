import { Container, Row, Col } from 'react-bootstrap';
import ProfileSection from '../../../components/organisms/description-sections/profile-update-section/ProfileSection';
import { NoseButtonList, NT_SUB_TITLE, NT_TITLE } from './constants';
import { useState } from 'react';
import NoseButtons from '../../../components/molecules/Buttons/NoseButtons/NoseButtons';
interface NoseTypeProps {
  onDataChange?: (noseType: string) => void;
}

const NoseType = ({ onDataChange }: NoseTypeProps) => {
  const handleSelectedType = (title: string) => {
    if (onDataChange) {
      onDataChange(title);
    }
  };
  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Row className="text-center w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center left-tab w-100 flex-column"
        >
          <ProfileSection title={NT_TITLE} description={NT_SUB_TITLE} />

          <div className="flex-container" style={{ marginTop: '50px' }}>
            {NoseButtonList.map((button, index) => (
              <div className="flex-item">
                <NoseButtons
                  key={index + 1}
                  src={button.src}
                  title={button.title}
                  onClick={handleSelectedType}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NoseType;
