import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CreateUserTimeSlotRequest, TimeSlotResponse } from "../types";
import axiosInstance from "../../../app/api/axiosInstance";
import { STATE_KEY, USER__URLS } from "../constants";


export const createUserTimeSlot = createAsyncThunk<TimeSlotResponse, CreateUserTimeSlotRequest, { state: RootState }>(
  `${STATE_KEY}/createUserTimeSlot`,
  async (requestData, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.accessToken;

      const response = await axiosInstance.put<TimeSlotResponse>(USER__URLS.CREATE_TIMESLOTS,requestData, {
        
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      
      return response.data;
    } catch (error: any) {
      console.error("Error fetching time-slot:", error);
      return rejectWithValue(error.response?.data || "Failed to fetch time-slot");
    }
  }
);
