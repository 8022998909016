export const STATE_KEY = 'auth';

export enum AUTH_URLS {
    LOGIN = '/auth/login',
    REGISTER = '/auth/register',
    VERIFY_USER = '/auth/verify',
    FORGOT_PASSWORD = '/auth/forgot-password',
    RESET_PASSWORD = '/auth/reset-password',
    GOOGLE_LOGIN = '/auth/google',
    PROFILE_UPDATE = '/user/update-profile',
    GET_USER = '/user/by-email/'
}

export const API_BASE_URL = process.env.REACT_APP_API_URL;
