const errorMessages: Record<number| string, string> = {
    'User already exists with this email': 'User already exists with this email. Please Login',
    500: 'An unexpected error occurred. Please try again later.',
  };
  
  export const getSignUpErrorMessage = (errorCode: number | string): string => {
    console.log(errorCode);
    
    return errorMessages[errorCode] || 'An unknown error occurred. Please try again.';
  };
  

  export enum GENERIC_WARNINGS {
    CHECKBOX = 'Please click the checkbox',
  }

  export interface ErrorMessage  {
    message: string,
    error: string,
    statusCode?: number,
  }