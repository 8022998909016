import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, UserTimeSlotResponse, UserTimeSlotState } from "./types";
import { STATE_KEY } from "./constants";
import { getUserTimeSlot } from "./thunks/getUserTimeSlots";

const userTimeSlotSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getUserTimeSlot.fulfilled,
      (state: UserTimeSlotState, action: PayloadAction<UserTimeSlotResponse>) => {
       state.userTimeslots = action.payload;
      }
    );
  },
});

export default userTimeSlotSlice.reducer;
