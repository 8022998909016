import { Link } from 'react-router-dom';
import {
  ALIGNMENT,
  FONT_SIZE,
  FONT_WEIGHT,
  FONT_WEIGHT_URL,
  LETTER_SPACING,
  LINE_HEIGHT,
  LOGIN_URL,
  SMALL_MOBILE_FONT_SIZE,
  SMALL_MOBILE_LINE_HEIGHT,
  TITLE,
  URL_TITLE,
} from '.';
import Title from '../../../../atoms/texts/title-text/Title';

interface LoginPromptProps {}

const LoginPrompt = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Title
        text={TITLE}
        alignment={ALIGNMENT}
        fontSize={FONT_SIZE}
        fontWeight={FONT_WEIGHT}
        lineHeight={LINE_HEIGHT}
        letterSpacing={LETTER_SPACING}
        smallMobileFontSize={SMALL_MOBILE_FONT_SIZE}
        smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT}
      />
      &nbsp;
      <Link to={LOGIN_URL}>
        <Title
          text={URL_TITLE}
          alignment={ALIGNMENT}
          fontSize={FONT_SIZE}
          fontWeight={FONT_WEIGHT_URL}
          lineHeight={LINE_HEIGHT}
          letterSpacing={LETTER_SPACING}
          smallMobileFontSize={SMALL_MOBILE_FONT_SIZE}
          smallMobileLineHeight={SMALL_MOBILE_LINE_HEIGHT}
        />
      </Link>
    </div>
  );
};

export default LoginPrompt;
