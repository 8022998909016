export interface TimeSlotDetails{
    pleasureToMe: string;
    birthDate: string;
    nickName: string;
    realName: string;
    gender: string;
    noseType?: string;
    timeSlot: {
      date: string;
      time: string;
      tutorials: string[];
    };
}

export interface CreateUserTimeSlotRequest {
    timeslots: TimeSlotDetails;
    timeSlotId?: string;
    userId: string;
  }
  
  export interface TimeSlotResponse {
    message: string;
  }

  export type UserTimeSlotResponse = CreateUserTimeSlotRequest[];


  export interface UserTimeSlotState {
    userTimeslots: UserTimeSlotResponse | undefined;
}

export const initialState: UserTimeSlotState = {
    userTimeslots: undefined,
}