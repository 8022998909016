import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
interface BackButtonProps {}

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Container fluid>
      <Row className="w-100">
        <Col xs={12} className="d-flex ">
          <div className="back-button">
            <img
              width="20"
              height="20"
              src="https://img.icons8.com/ios/50/left--v1.png"
              alt="left--v1"
              onClick={handleBack}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BackButton;
