import { createAsyncThunk } from "@reduxjs/toolkit";
import { RawUserProfileResponse, UserProfileResponse } from "../types";
import axiosInstance from "../../../app/api/axiosInstance";
import { AUTH_URLS, STATE_KEY } from "../constants";
import { RootState } from "../../../app/store";

const getUser = createAsyncThunk<UserProfileResponse>(
    `${STATE_KEY}/getUser`,
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState() as RootState;
            const token = state.auth.accessToken;
            const email = state.auth.user?.email;

            const response = await axiosInstance.get<RawUserProfileResponse>(
                `${AUTH_URLS.GET_USER}${email}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const data = response.data;

            const profile: UserProfileResponse = {
                Username: data.Username,
                Email: data.Attributes.find(attr => attr.Name === "email")?.Value || '',
                EmailVerified: data.Attributes.find(attr => attr.Name === "email_verified")?.Value === "true",
                Nickname: data.Attributes.find(attr => attr.Name === "nickname")?.Value || '',
                Name: data.Attributes.find(attr => attr.Name === "name")?.Value || '',
                FamilyName: data.Attributes.find(attr => attr.Name === "family_name")?.Value || '',
                GivenName: data.Attributes.find(attr => attr.Name === "given_name")?.Value || '',
                Preferred_username: data.Attributes.find(attr => attr.Name === "preferred_username")?.Value || '',
                Picture: data.Attributes.find(attr => attr.Name === "picture")?.Value || '',
                Gender: data.Attributes.find(attr => attr.Name === "gender")?.Value || '',
                Birthdate: data.Attributes.find(attr => attr.Name === "birthdate")?.Value || '',
                UserStatus: data.Attributes.find(attr => attr.Name === "custom:user-status")?.Value || '',
                Experience: data.Attributes.find(attr => attr.Name === "custom:experience")?.Value || '',
                ProfileUpdate: data.Attributes.find(attr => attr.Name === "custom:profile-update")?.Value || '',
                Sub: data.Attributes.find(attr => attr.Name === "sub")?.Value || '',
                UserCreateDate: data.UserCreateDate,
                UserLastModifiedDate: data.UserLastModifiedDate,
                Enabled: data.Enabled,
                UserStatusCode: data.UserStatus,
            };

        return profile;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch user profile');
        }
    }
);

export {getUser}