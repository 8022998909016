import Feel1 from '../../../../assests//images/feel1.png';
import Feel2 from '../../../../assests//images/feel2.png';
import Feel3 from '../../../../assests//images/feel3.png';

interface TimeButtonProps {
  time?: string;
  type?: string;
  isSelected: boolean;
}

const TimeButton = ({ time, type, isSelected }: TimeButtonProps) => {
  let iconSrc;
  switch (type) {
    case 'peak':
      iconSrc = Feel3;
      break;
    case 'high':
      iconSrc = Feel1;
      break;
    case 'low':
      iconSrc = Feel2;
      break;
    default:
      iconSrc = '';
  }

  return (
    <div
      className="time-button"
      style={{
        backgroundColor: isSelected ? 'black' : 'white',
      }}
    >
      <img src={iconSrc} alt="" />
      <span className="time" style={{ color: isSelected ? 'white' : 'black' }}>
        {time}
      </span>
      <span className="type" style={{ color: isSelected ? 'white' : 'black' }}>
        {type}
      </span>
    </div>
  );
};

export default TimeButton;
