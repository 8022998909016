import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
interface ProgressProps {}

const Progress = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const targetValue = 100;
    const increment = 10;

    const timer = setInterval(() => {
      setValue(prevValue => {
        if (prevValue >= targetValue) {
          clearInterval(timer);
          return targetValue;
        }
        return prevValue + increment;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (value === 100) {
      navigate('/dashboard');
    }
  }, [value]);

  return (
    <div className="progress-wrapper">
      <CircularProgressbar value={value} text={`${value}%`} />
    </div>
  );
};

export default Progress;
