import ReactDatePicker from 'react-datepicker';
import { FormikErrors } from 'formik/dist/types';
import { InputFieldProps, InputFormType, InputState } from '../../../types/InputField';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps extends InputFieldProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDate: (date: Date) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  selected: Date;
}

const DatePicker = ({
  name,
  value,
  onChange,
  onBlur,
  type = InputFormType.Text,
  label,
  showLeftIcon = true,
  placeholder,
  error,
  leftIcon,
  selected,
  handleDate,
}: DatePickerProps) => {
  return (
    <div className="input-field" style={{ height: 'auto' }}>
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}

      <div className="input-wrapper">
        {showLeftIcon && leftIcon}

        <ReactDatePicker
          className="input"
          selected={selected}
          onChange={date => handleDate(date as Date)}
          placeholderText={placeholder}
          name={name}
          onBlur={onBlur}
        />
      </div>

      {error && <div className="input-error">{error as string}</div>}
    </div>
  );
};

export default DatePicker;
