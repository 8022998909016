import { Spinner } from 'react-bootstrap';
import { CircleSpinner } from 'react-spinners-kit';
interface SpinnerProps {
  size?: string;
  color?: string;
  marginTop?: string;
}

const SpinnerWrapper = ({ color, marginTop }: SpinnerProps) => {
  return (
    <div className="spinnerWrapper" style={{ marginTop: marginTop }}>
      <Spinner animation="border" style={{ color }} />
    </div>
  );
};

export default SpinnerWrapper;
