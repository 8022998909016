export enum InputFormType {
    Password = "password",
    Email = "email",
    Text = 'text'
  }
  
  export enum InputState {
    Default = "default",
    Error = "error",
  }
  
  export interface InputFieldProps {
    type: InputFormType;
    state: InputState;
    label?: string;
    darkMode?: boolean;
    showLeftIcon?: boolean;
    showRightIcon?: boolean;
    placeholder?: string;
  }
  