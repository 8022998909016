import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import BaseModal from '../../atoms/modal/modal';
import { LoaderTitleTypes } from '../../types/loaderTitleTypes';
import SpinnerWrapper from '../../atoms/spinner/spinner';

interface LoaderModalProps {
  show: boolean;
  onHide: () => void;
  loaderTitle: string;
}

const LoaderModal: React.FC<LoaderModalProps> = ({ show, onHide, loaderTitle }) => {
  return (
    <BaseModal
      show={show}
      onHide={onHide}
      padding="32px"
      gap="24px"
      borderRadius="12px"
      opacity={0.9}
    >
      <div className="d-flex flex-column align-items-center justify-content-center children-modal">
        <SpinnerWrapper color="#F28E2E" />
        <p className="mt-3">{loaderTitle}</p>
      </div>
    </BaseModal>
  );
};

export default LoaderModal;
